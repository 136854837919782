import React, { useState } from 'react'
import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import Back from '../../Components/Back/Back';
import { serviceConsumer } from '../../network/ServiceConsumer';
import { formatDate } from '../../helper/formatDate';
import GoLiveConfirmationModal from '../../Components/Modals/OrganizerModel/GoLiveConfirmationModal';
import Loader from '../../Components/Loader/Loader';

const GoLiveTitle = ({ eventDetails, type }) => {
    const [loading, setLoading] = useState(false)
    const [openGoLiveModal, setOpenGoLiveModal] = useState(false)
    const navigate = useNavigate()
    const id = localStorage.getItem('ID');
    const date = type === "Feisanna"
        ? moment(formatDate(eventDetails?.feis_date)).format('MMMM Do, YYYY')
        : moment(formatDate(eventDetails?.exam_date)).format('MMMM Do, YYYY')

    const onBackClick = () => {
        navigate(type === "Feisanna" ? '/feis' : "/grade")
    }

    const goLiveHandler = async () => {
        setLoading(true)
        try {
            const feisUrl = `${process.env.REACT_APP_BASE_URL}/feis/golive `;
            const gradeUrl = `${process.env.REACT_APP_BASE_URL}/grade-exam/golive `;
            const url = type === "Feisanna" ? feisUrl : gradeUrl;
            const response = await serviceConsumer('POST', url, { id });
            if (response.success === true) {
                setOpenGoLiveModal(true)
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    };


    return (
        <>
            {loading && <Loader />}
            {openGoLiveModal && <GoLiveConfirmationModal openModal={openGoLiveModal} setOpenModal={setOpenGoLiveModal} from="goLive" type={type} />}
            {<Container style={{ background: '#FFFFFF', marginBottom: '10px' }}>
                <div className="headingOrg p-0">
                    <div className="d-flex backContainer" style={{ color: '#68AD3F', cursor: 'pointer' }} >
                        <Back onBackClick={onBackClick} />
                        <div className="organizerExam"></div>
                    </div>
                </div>
                <div className="EventDetailss ">
                    <div className="finalMainHead">
                        <div className="logoImg">
                            <img src={eventDetails?.logo} alt="logo" />
                            <span className="EventNAme">{eventDetails?.name}</span>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between" >
                        <div>
                            <span className="ExamNAme p-0">{type}</span>
                            <span className="examVenue"> {date}</span>
                        </div>
                        <div className="goLiveButton">
                            <button style={{ fontWeight: '500' }} type="submit" onClick={goLiveHandler}>
                                Go Live
                            </button>
                        </div>
                    </div>
                </div>
            </Container>}
        </>
    )
}

export default GoLiveTitle