import React from 'react'
import "./Checkbox.css"

const Checkbox = ({ onChange, checked, name, disabled }) => {
 
    return (
        <label className={disabled ? "check-container-disabled" : "check-container"}>
            <input type="checkbox" onChange={onChange} checked={checked} name={name} disabled={disabled} />
            <span className="checkmark"></span>
        </label>
    )
}

export default Checkbox