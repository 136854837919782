import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import HeaderOrg from '../../../Headers/HeaderOrg';
import Help from '../../../../Components/Help/Help';
import Loader from '../../../../Components/Loader/Loader';
import { serviceConsumer } from '../../../../network/ServiceConsumer';
import ManageTitle from '../../../../Components/TitleElements/ManageTitle';
import { getPrintHandler } from '../../../../helper/printHandlers';
import Checkbox from "../../../../Components/Checkbox/Checkbox"
import TextError from '../../../../Components/formik/TextError';
import { sortCompetitions } from '../../../../helper/SortSyllabus';

const CheckIn = () => {
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const eventId = searchParams.get('id')

    const [event, setEvent] = useState()
    const [levels, setLevels] = useState([])
    const [competitions, setCompetitions] = useState([])
    const [competitors, setCompetitors] = useState([])
    const [competitionCode, setCompetitionCode] = useState("")
    const [competitionGroup, setCompetitionGroup] = useState("")
    const [competitionId, setCompetitionId] = useState("")
    const [loading, setLoading] = useState(false)
    const [initialLoading, setInitialLoading] = useState(false)
    const [err, setErr] = useState("")

    const getInitialDetails = async () => {
        try {
            setInitialLoading(true)
            const eventUrl = `${process.env.REACT_APP_BASE_URL}/feis/${eventId}`
            const eventResponse = await serviceConsumer('GET', eventUrl)
            setEvent(eventResponse.Feis)

            const levelUrl = `${process.env.REACT_APP_BASE_URL}/feis/levels/checkIn`
            const levelPayload = {
                id: eventId,
            }
            const levelResponse = await serviceConsumer("POST", levelUrl, levelPayload)
            setLevels(levelResponse.result)

            const competitionsUrl = `${process.env.REACT_APP_BASE_URL}/feis/level/competitions`
            const competitionsPayload = {
                feis_id: eventId,
                level: levelResponse.result[0]
            }
            const competitionsResponse = await serviceConsumer("POST", competitionsUrl, competitionsPayload)
            const competitionsWithDancers = competitionsResponse.result.filter(competition => competition.dancers.length > 0)
            const sortedCompetitionsWithDancers = sortCompetitions(competitionsWithDancers)
            setCompetitions(sortedCompetitionsWithDancers)
            setCodeIdGroup(sortedCompetitionsWithDancers[0])

            const competitorsUrl = `${process.env.REACT_APP_BASE_URL}/feis/competition/dancers/${competitionsWithDancers[0]._id}`;
            const competitorsResponse = await serviceConsumer("GET", competitorsUrl)
            setCompetitors(competitorsResponse.result)
            setInitialLoading(false)
        } catch (error) {
            setInitialLoading(false)
        }
    };

    useEffect(() => {
        getInitialDetails();
    }, []);

    useEffect(() => {
        setErr("")
    }, [competitionId, levels,])

    // get competitions api
    const getCompetitions = async (level) => {
        try {
            setLoading(true)
            const payload = {
                feis_id: eventId,
                level: level
            }
            const url = `${process.env.REACT_APP_BASE_URL}/feis/level/competitions`;
            const response = await serviceConsumer("POST", url, payload)
            const competitionsResponse = response.result;
            const competitionsWithDancers = competitionsResponse.filter(competition => competition.dancers.length > 0)
            const sortedCompetitionsWithDancers = sortCompetitions(competitionsWithDancers)
            setCompetitions(sortedCompetitionsWithDancers)
            setCodeIdGroup(sortedCompetitionsWithDancers[0])
            getCompetitors(sortedCompetitionsWithDancers[0]._id)
            setLoading(false)
        }
        catch (error) {
            setLoading(false)
        }
    }

    const setCodeIdGroup = (competition) => {
        setCompetitionCode(competition.code)
        setCompetitionId(competition._id)
        setCompetitionGroup(competition.group)
    }

    //get dancers of the competition
    const getCompetitors = async (id) => {
        try {
            setLoading(true)
            const url = `${process.env.REACT_APP_BASE_URL}/feis/competition/dancers/${id}`;
            const response = await serviceConsumer("GET", url)
            setCompetitors(response.result)
            setLoading(false)
        }
        catch (error) {
            setLoading(false)
        }
    }

    //level select handler
    const handleLevelChange = (event) => {
        getCompetitions(event.target.value)
    }

    //competition select handler
    const handleCompetitionChange = (event) => {
        let competitionId = event.target.value
        let selectedCompetition = competitions.find((competition) => competition._id === competitionId)
        setCodeIdGroup(selectedCompetition)
        getCompetitors(competitionId)
    }

    //print check-in-sheet handler
    const textBtnHandler = async () => {
        const url = `${process.env.REACT_APP_BASE_URL}/feis/checkInSheet/pdf/${competitionId}`;
        getPrintHandler(url, setLoading);
    }

    const checkBoxHandler = async (dancer) => {
        const payload = {
            reg_id: dancer._id,
            check: !(isChecked(dancer)),
            compId: competitionId
        }
        try {
            const url = `${process.env.REACT_APP_BASE_URL}/feis/checkStatus/change`;
            await serviceConsumer("POST", url, payload)
            setErr("")
            getCompetitors(competitionId)
        } catch (err) {
            setErr(err.messages);
        }
    }

    const isChecked = (dancer) => {
        return dancer.checked.some(item => item.competitionId === competitionId && item.check)
    }

    return (
        <>
            {loading && <Loader />}
            {initialLoading && <Loader />}
            <HeaderOrg />
            <ManageTitle
                eventLogo={event?.logo}
                eventName={event?.name}
                eventType={event?.eventType}
                eventDate={event?.feis_date}
                textButton={"Print check-in sheets"}
                className={competitions?.length > 0 ? 'text-btn' : 'text-btn-disabled'}
                textBtnHandler={textBtnHandler}
                loading={initialLoading} />

            {!initialLoading && <div className="org-body-white">
                <div class="org-body-title pt-4">Check-in Sheet</div>
                <div class="mt-1 hr-org-body"></div>
                <div className="d-md-flex mt-4 gap-md-4">
                    <div>
                        <div className="search-label">Select a level:</div>
                        <select name="level" className="check-in-select" onChange={handleLevelChange} value={competitionGroup}>
                            {levels?.map(levelOption => <option key={uuidv4()} value={levelOption} >{levelOption}</option>)}
                        </select>
                    </div>
                    <div className='mt-4 mt-md-0'>
                        <div className="search-label">Select a competition:</div>
                        <select name="competition" className="check-in-select" onChange={handleCompetitionChange} value={competitionId}>
                            {competitions?.map(competitionOption => <option key={uuidv4()} value={competitionOption._id} >{competitionOption.code}-{competitionOption.group}</option>)}
                        </select>
                    </div>
                </div >
                <div style={{ width: "100%", overflowX: "scroll", marginTop: "30px", marginBottom: "50px" }}>
                    <table className="table my_table competitorTable ">
                        <tbody>
                            <tr className="header-row">
                                <td className="text-center mx-5 w-6 p-25-5"></td>
                                <td className="text-center mx-5 w-6 p-25-5">#</td>
                                <td className="mx-5 w-25 p-25-5">Competitor</td>
                                <td className="w-33 p-25-5">School Name</td>
                                <td className="text-center w-20 p-25-5">DOB</td>
                                <td className='text-center w-10 p-25-5'>Competition</td>
                            </tr>
                            {competitors?.map((dancer,) => {
                                return <tr key={dancer?.card_number} style={{ background: "#FFFFFF", fontSize: "12px", height: "31px" }}>
                                    <td className="text-center mx-5 w-6 grey-42 p-25-5"><Checkbox onChange={() => checkBoxHandler(dancer)} checked={isChecked(dancer)} /></td>
                                    <td className="text-center mx-5 py-4 w-6 grey-30">{dancer?.card_number}</td>
                                    <td className="mx-5 w-25 grey-30">{dancer?.dancerData?.fname}&nbsp;{dancer?.dancerData?.lname}</td>
                                    <td className="w-33 grey-30">{dancer?.dancerData?.school}</td>
                                    <td className="text-center w-20 grey-30">{dancer?.dancerData?.birthday}</td>
                                    <td className='text-center w-10 grey-30'>{competitionCode}</td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                    {!loading && competitors.length === 0 && <div className="d-flex justify-content-center my-5">There are no competitors registered for this event</div>}
                    {err && <TextError>{err}</TextError>}
                </div>
            </div>}

            <Help />

        </>
    )
}

export default CheckIn