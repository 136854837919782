import React, { useState } from 'react';
import { Field } from 'formik';

import FormikControl from '../../../../Components/formik/FormikControl';
import { feisCapData, gradeCapData, lavelCap } from '../../../../helper/OrgDataArrays';
import { handleWheel, handleKeyDown } from '../../../../helper/handleActions';


const CapOptions = ({ formik, props, levelCap, SetlevelCap }) => {
    const [showGlobalCap, SetShowGlobalcap] = useState(false);
    const [ShowGradeNumber, setShowGradeNumber] = useState(false);

    const changeCapHandler = (capValue, setShowGradeNumber, SetShowGlobalcap, SetlevelCap) => {
        if (capValue === 'No Cap') {
            setShowGradeNumber(false);
            SetShowGlobalcap(false);
            SetlevelCap(false);
        } else if (capValue === 'Global Cap') {
            SetShowGlobalcap(true);
            SetlevelCap(false);
            setShowGradeNumber(false);
        } else if (capValue === 'Level Cap') {
            SetlevelCap(true);
            SetShowGlobalcap(false);
            setShowGradeNumber(false);
        }
    };
    return <>
        <div><h3 className="EventDateOrg">Options</h3></div>
        <div className={formik.errors.cap?.capType && formik.touched.cap?.capType ? '' : 'input-control'}>
            <label
                style={{ fontSize: '12px' }}
                className={formik.errors.cap?.capType && formik.touched.cap?.capType ? 'label-select-org-error' : 'org-select'}
            >
                Cap:
            </label>
            <FormikControl
                className={formik.errors.cap?.capType && formik.touched.cap?.capType ? 'input-box-select-error ' : 'input-box-org-select'}
                control="select"
                name="cap.capType"
                onWheel={handleWheel}
                onKeyDown={handleKeyDown}
                showAs="Select"
                options={props.type === "feis" ? feisCapData : gradeCapData}
                id="schoolSelect"
                setFieldValue={formik.setFieldValue}
                onChange={(e) => {
                    formik.setFieldTouched('cap.capType');
                    formik.setFieldValue('cap.value', '');
                    formik.setFieldValue('cap.grades', '');
                    formik.setFieldValue('cap.champ', '');
                    formik.setFieldTouched('cap.value', false);
                    formik.setFieldTouched('cap.grades', false);
                    formik.setFieldTouched('cap.champ', false);
                    formik.setFieldValue('cap.capType', e.target.value);
                    changeCapHandler(e.target.value, setShowGradeNumber, SetShowGlobalcap, SetlevelCap);
                }}
                onClick={() => {
                    formik.validateField('cap.capType')
                    formik.validateForm()
                }}
            />
        </div>
        {levelCap && (
            <div className="levelCap-Values">
                {renderLevelCapInputs(formik, 0, "grades", formik.touched.cap?.grades)}
                {renderLevelCapInputs(formik, 1, "champ", formik.touched.cap?.champ)}
            </div>
        )}

        {showGlobalCap && (
            <>
                {renderNumberInput(formik)}
                {formik.errors.cap?.value && formik.touched.cap?.value && <div className="error">{formik.errors.cap?.value}</div>}
                <div />
                <br />
            </>
        )}
        {ShowGradeNumber && (
            <div className={!formik.errors.value ? 'input-control' : ''}>
                {renderNumberInput(formik)}
                {formik.errors.cap?.value && formik.touched.cap?.value && (<div className="error">{formik.errors.cap?.value}</div>)}
                <br />
            </div>
        )}
    </>
}

export default CapOptions

const renderNumberInput = formik => {
    return <Field
        className={formik.errors.cap?.value && formik.touched.cap?.value ? 'input-box-org-error mt-2' : 'input-box-org mt-2'}
        type="number"
        name="cap.value"
        placeholder="Enter Number"
        step="any"
        onWheel={handleWheel}
        onKeyPress={(e) => { if (e.key === 'e') { e.preventDefault(); } }}
    />
}

const renderLevelCapInputs = (formik, index, keyWord, touchvalidation) => {
    return <div className={!formik.values.cap?.grades && !formik.values.cap?.champ && touchvalidation ? '' : 'input-control'}>
        <label
            className={!formik.values.cap?.grades && !formik.values.cap?.champ && touchvalidation ? 'label-select-org-error' : 'org-select'}
        >
            {lavelCap[index].key}
            <div className="subKey">{lavelCap[index].subKey}</div>
        </label>
        <Field
            className={!formik.values.cap?.grades && !formik.values.cap?.champ && touchvalidation ? 'input-box-org-error' : 'input-box-org'}
            type="number"
            name={keyWord === "grades" ? "cap.grades" : "cap.champ"}
            placeholder="Enter Number"
            step="any"
            onWheel={handleWheel}
            onKeyPress={(e) => { if (e.key === 'e') { e.preventDefault(); } }}
        />
    </div>
}