import React, { useState, useRef, useEffect } from 'react'
import * as Yup from 'yup';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Button } from '@mui/material';
import Accordion from 'react-bootstrap/Accordion';
import Table from 'react-bootstrap/Table';
import { v4 as uuidv4 } from 'uuid';
import { Formik, Field, FieldArray, ErrorMessage, Form } from 'formik';
import Loader from '../../../../Components/Loader/Loader';
import { agesCode22, genderData, Rounddata, AdjData, levelData, agesCodeObj1, dancersData } from './SyllabusArrays';
import Checkbox from '../../../../Components/Checkbox/Checkbox';
import { serviceConsumer } from '../../../../network/ServiceConsumer';
import { ShowToastMessage } from '../../../../helper/ShowToastMessage';
import { AddButton, OutlinedButton } from '../Buttons';


const SyllabusTable = ({ levelDetailProp, feisId, getSyllabusDetails }) => {
    const addNewRef = useRef()
    const [loading, setLoading] = useState(false)
    const [levelDetail, setLevelDetail] = useState(levelDetailProp)
    const [showFieldError, setShowFieldError] = useState(false)
    const [deletedArray, setDeletedArray] = useState([])
    const [codeArray, setCodeArray] = useState([]);
    const [secChanceErrorIndex, setSecChanceErrorIndex] = useState()

    useEffect(() => {
        setLevelDetail(levelDetailProp);
    }, [levelDetailProp]);

    const mapBoysAndGirlsOptions = (boys, girls) => {
        if (boys && girls) return "Both";
        if (boys) return "Boys/Men";
        if (girls) return "Girls/Ladies";
        return "";
    };
    const getCompetionsArr = (ages) => {
        let arr = [];
        Object.keys(agesCodeObj1).forEach(age => {
            if (ages.includes(age)) {
                arr = [...arr, ...agesCodeObj1[age]];
            }
        });
        return arr;
    }

    const getDancers = (competition) => {
        if (competition) {
            if (competition?.rounds == 1) {
                return competition?.dancerCount?.length > 0
                    ? competition?.dancerCount
                    : [{ round: 1, numberOfDancers: 2 },];
            } else {
                return competition?.dancerCount?.length > 0
                    ? competition?.dancerCount
                    : [
                        { round: 1, numberOfDancers: 2 },
                        { round: 2, numberOfDancers: 2 },
                    ];
            }
        }
        return [];
    };

    const initialValues = {
        tableData: (levelDetail?.competitions || []).map((competition) => {
            return {
                code: competition?.code,
                group: competition?.group,
                gender: mapBoysAndGirlsOptions(competition?.boys, competition?.girls) || "",
                isChecked: competition?.isChecked || '',
                ages: getCompetionsArr(competition?.ages) || [],
                levels: competition?.levels || [],
                price: competition?.price || 0,
                max_participants: competition?.max_participants || 1000,
                description: competition?.description || '',
                adjudicators: competition?.adjudicators || '',
                rounds: competition?.rounds || '',
                _id: competition?._id || "",
                dancerCount: getDancers(competition),
                newCompetition: competition?.newCompetition || false,
                duplicatedId: competition?.duplicatedId || "",
                makeDuplicationDisabled: false,
                levelArray: competition?.levelArray || "",
                feis_id: feisId,
                duplicateCode: competition?.duplicateCode,
                second_chance: competition?.second_chance || false
            };
        }),
    }

    const validationSchema = Yup.object().shape({
        tableData: Yup.array().of(
            Yup.object().shape({
                code: Yup.string().required(""),
                ages: Yup.array().required(""),
                price: Yup.number().required(""),
                max_participants: Yup.number().required(""),
                description: Yup.string().required(""),
            })
        ),
    })

    const setCompetitionsWithGender = (competitions) => {
        return competitions.map(competition => {
            return {
                ...competition,
                boys: (competition.gender === "Both" || competition.gender === "Boys/Men"),
                girls: (competition.gender === "Both" || competition.gender === "Girls/Ladies")
            }
        })
    }

    const saveChangesHandler = async (values) => {
        const competitions = values.tableData
        const competitionsWithGender = setCompetitionsWithGender(competitions)
        const saveUrl = `${process.env.REACT_APP_BASE_URL}/feis/save/changes`
        const deleteUrl = `${process.env.REACT_APP_BASE_URL}/feis/delete/competitions`
        const savePayload = {
            feis_id: feisId,
            competitions: competitionsWithGender,
        }
        const deletePayload = {
            feis_id: feisId,
            competitionIds: deletedArray,
            Code: codeArray,
            type: levelDetail._id.toUpperCase()
        }
        try {
            setLoading(true);
            await serviceConsumer("POST", saveUrl, savePayload);
            ShowToastMessage("success", "Syllabus saved successfully")
            deletedArray.length > 0 && await serviceConsumer("POST", deleteUrl, deletePayload)
            setDeletedArray([])
            getSyllabusDetails()
            setLoading(false);
        } catch (err) {
            setLoading(false)
        }
    }

    const updateLevelHandler = (values, setFieldValue) => {
        const updatedTableData = values.tableData.map((item) => ({
            ...item,
            price: values?.price || item?.price,
            max_participants: values.dancer || item.max_participants,
        }));
        setFieldValue('tableData', updatedTableData);
    };

    const invokeAddNewRowButton = (values) => {
        if (isFormEmpty(values)) {
            setShowFieldError(true)
        } else {
            setShowFieldError(false)
            addNewRef.current && addNewRef.current.click && addNewRef.current.click()
        }
    }

    const getLevelsForNewRow = () => {
        if (levelDetail?._id.toLowerCase() === "specials" || levelDetail?._id.toLowerCase() === "teams") return []
        else return [levelDetail._id]
    }

    const addNewRow = (e, push, values) => {
        const prevRow = values?.tableData?.length > 0 && values.tableData[values.tableData.length - 1]
        e.preventDefault()
        push({
            code: '',
            group: levelDetail._id,
            gender: 'Both',
            isChecked: false,
            ages: [],
            levels: getLevelsForNewRow(),
            price: prevRow ? prevRow?.price : '',
            max_participants: prevRow ? prevRow.max_participants : '',
            description: '',
            adjudicators: '1',
            rounds: '1',
            id: '',
            dancerCount: getDancers({ rounds: 1 }),//since getDancers is manipulated based on comp.rounds
            newCompetition: true,
            duplicatedId: "",
            makeDuplicationDisabled: true,
            feis_id: feisId,
            second_chance: false
        });
    };

    const deleteLevelHandler = async () => {
        const compIds = levelDetail?.competitions.map(comp => comp._id)
        const payload = {
            feis_id: feisId,
            competitionIds: compIds
        }
        try {
            setLoading(true)
            const url = `${process.env.REACT_APP_BASE_URL}/feis/delete/competitions`
            await serviceConsumer("POST", url, payload)
            getSyllabusDetails()
            const updatedLevelDetail = { ...levelDetail, _id: "" }
            setLevelDetail(updatedLevelDetail)
            setLoading(false)
        }
        catch (err) {
            setLoading(false)
        }

    }

    const deleteComp = (e, index, remove, values, setFieldValue) => {
        const compDetails = values.tableData[index];
        e.preventDefault();
        e.stopPropagation();

        if (compDetails.isChecked) {
            // Helper function to update isChecked property for a specific row
            const updateIsChecked = (rowIndex, isChecked) => {
                const currentRowData = { ...values.tableData[rowIndex], isChecked, duplicatedId: "" };
                const updatedTableData = [...values.tableData];
                updatedTableData[rowIndex] = currentRowData;
                setFieldValue('tableData', updatedTableData);
            };
            const pairCompIndex = values.tableData.findIndex((item, i) => item.duplicatedId === compDetails.duplicatedId && i !== index)
            updateIsChecked(pairCompIndex, false)
        }
        if (compDetails?._id) {
            const deletedArrayCopy = [...deletedArray]
            deletedArrayCopy.push(compDetails._id);
            setDeletedArray(deletedArrayCopy)

            const codeArrayCopy = [...codeArray]
            codeArrayCopy.push(compDetails.code)
            setCodeArray(codeArrayCopy)
        }
        remove(index);
    };

    const handleCheckboxChange = (index, values, setFieldValue) => {
        if (isRowValid(index, values) && !values.tableData[index].isChecked) {
            const currentRowData = { ...values.tableData[index], isChecked: true, duplicatedId: values.tableData[index]._id || values.tableData[index].duplicatedId };
            const updatedTableData = [...values.tableData];
            const duplicatedAges = [...currentRowData.ages];
            updatedTableData[index] = currentRowData;
            const duplicatedRow = {
                ...currentRowData, code: currentRowData.code + "-2", description: currentRowData.description + "-second", id: uuidv4(), ages: duplicatedAges, newCompetition: true,
                duplicatedId: currentRowData.duplicatedId, second_chance: true
            };
            updatedTableData.splice(index + 1, 0, duplicatedRow);
            setFieldValue('tableData', updatedTableData);
        }
    };

    const handleAdjudicatorsChange = (e, index, setFieldValue) => {
        const selectedAdjudicator = e.target.value;
        setFieldValue(`tableData[${index}].adjudicators`, selectedAdjudicator);

        if (selectedAdjudicator === "9") {
            setFieldValue(`tableData[${index}].rounds`, "3");
        }
    };

    const handleWheel = (e) => {
        e.preventDefault();
        e.currentTarget.blur(); // Remove focus from the input field
    };

    const isFormEmpty = (values) => {
        // Check if any of the fields are empty
        return values.tableData.some(
            item => item.code === "" || item.ages.length === 0 || item?.price === "" || item.max_participants === "" || item.description === "");
    }

    const isRowValid = (index, values) => {
        setSecChanceErrorIndex(index)
        return values.tableData[index].code !== "" && values.tableData[index].ages.length !== 0 &&
            values.tableData[index]?.price !== "" && values.tableData[index].max_participants !== "" &&
            values.tableData[index].description !== ""
    }

    const getParticipantsClassName = (values, touched, index) => {
        if (values.tableData[index].max_participants === "" && ((touched.tableData && touched.tableData[index] && touched.tableData[index].max_participants) || showFieldError || secChanceErrorIndex === index))
            return "error-input"
        else return "sylcode"
    }
    const getCodeClassName = (values, touched, index) => {
        if (values.tableData[index].code === "" && touched.tableData && touched.tableData[index] && touched.tableData[index].code)
            return "error-input"
        else if (values.tableData[index].code === "" && showFieldError)
            return "error-input"
        else return "sylcode"
    }

    const getDescClassName = (values, touched, index) => {
        if (values.tableData[index].description === "" && touched.tableData && touched.tableData[index] && touched.tableData[index].description)
            return "error-des"
        else if (values.tableData[index].description === "" && showFieldError)
            return "error-des"
        else return "syldes"
    }
    const getPriceClassName = (values, touched, index) => {
        if (values.tableData[index]?.price === "" && ((touched.tableData && touched.tableData[index] && touched.tableData[index]?.price) || showFieldError || secChanceErrorIndex === index))
            return "error-input"
        else return "sylcode"
    }

    return (
        <>
            {loading && <Loader />}
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                key={levelDetail?.competitions?.length}
            >
                {({ values, touched, errors, setFieldValue }) => (
                    < Form >
                        {levelDetail._id && <Accordion >
                            <Accordion.Item eventKey="">
                                <Accordion.Header className="header-item" id="accordationHeader">
                                    <span className="userLevel">{levelDetail._id}</span>
                                    {!levelDetail.static && <Button className="deleteButton" onClick={deleteLevelHandler}>
                                        <span className="deleteIcon"><DeleteOutlineIcon />{' '}</span>
                                        <span className="deleteLevel" >Delete level</span>
                                    </Button>}
                                </Accordion.Header>
                                <Accordion.Body>
                                    <div className="updateLevelform" style={{ gap: '24px' }} >
                                        <div>
                                            <label>Change general price:</label>
                                            <Field
                                                name="price"
                                                placeholder="Price"
                                                type="number"
                                                autoComplete="off"
                                                className="sylmainInput"
                                                onWheel={handleWheel}
                                                onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                            />
                                        </div>
                                        <div>
                                            <label>Change general max dancers:</label>
                                            <Field
                                                name="dancer"
                                                placeholder="Dancers"
                                                className="sylmainInput"
                                                type="number"
                                                autoComplete="off"
                                                onWheel={handleWheel}
                                                onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                            />
                                        </div>
                                        <div>
                                            <OutlinedButton onClick={() => updateLevelHandler(values, setFieldValue)}>Update Level</OutlinedButton>
                                        </div>
                                    </div>
                                    <div style={{ width: "100%", overflowX: "scroll" }} >
                                        <Table className="mt-4 sylTable my_table" style={{ overflowX: 'scroll', }}>
                                            <FieldArray name="tableData">
                                                {({ push, remove }) => (
                                                    <>
                                                        <thead className="tableHeadSyl">
                                                            <tr className="header-row">
                                                                <th className="tableSylTh justify-content-center">CODE</th>
                                                                <th className="tableSylTh">DESCRIPTION</th>
                                                                <th className="tableSylTh">PRICE</th>
                                                                <th className="tableSylTh justify-content-center">AGES</th>
                                                                {((levelDetail?._id.toLowerCase() === "teams") || (levelDetail?._id.toLowerCase() === "specials")) && <th className="tableSylTh justify-content-center">LEVELS</th>}
                                                                <th className="tableSylTh">GENDER</th>
                                                                <th className="tableSylTh">MAX C.</th>
                                                                <th className="tableSylTh">#Adjudicators</th>
                                                                <th className="tableSylTh">Rounds</th>
                                                                <th className="tableSylTh">Dancers</th>

                                                                {levelDetail?._id.toLowerCase() !== "teams" && levelDetail?._id.toLowerCase() !== "specials" && <th className="tableSylTh">2ND</th>}
                                                                <th className="tableSylTh">ACTION</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {values.tableData.map((_, index) =>
                                                                <>
                                                                    < tr style={{ background: "#ffffff", height: "120px" }} key={_?._id} >
                                                                        <td>
                                                                            <Field name={`tableData[${index}].code`}
                                                                                placeholder="Code"
                                                                                type="text"
                                                                                className={getCodeClassName(values, touched, index)} />
                                                                            <ErrorMessage name={`tableData[${index}].code`} component="div" className='auth-error' />
                                                                        </td>
                                                                        <td>
                                                                            <Field name={`tableData[${index}].description`}
                                                                                placeholder="Description"
                                                                                type="text"
                                                                                className={getDescClassName(values, touched, index)} />
                                                                            <ErrorMessage name={`tableData[${index}].description`} component="div" className='auth-error' />
                                                                        </td>
                                                                        <td>
                                                                            <Field name={`tableData[${index}].price`}
                                                                                placeholder="Price"
                                                                                type="number"
                                                                                autoComplete="off"
                                                                                onWheel={handleWheel}
                                                                                onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                                                                className={getPriceClassName(values, touched, index)} />
                                                                            <ErrorMessage name={`tableData[${index}].price`} component="div" className='auth-error' />
                                                                        </td>
                                                                        <td >
                                                                            <div className="agesCodetd">
                                                                                {agesCode22.map((n) => (
                                                                                    <>
                                                                                        < label key={n} className={values.tableData[index].ages.includes(n) ? 'agesselected' : 'agesunselected'} >
                                                                                            <Field
                                                                                                type="checkbox"
                                                                                                name={`tableData[${index}].ages`}
                                                                                                value={n}
                                                                                                checked={values.tableData[index].ages.includes(n)}
                                                                                                onChange={(e) => {
                                                                                                    const { checked } = e.target;
                                                                                                    const newAges = checked
                                                                                                        ? [...values.tableData[index].ages, n]
                                                                                                        : values.tableData[index].ages.filter((age) => age !== n);
                                                                                                    setFieldValue(`tableData[${index}].ages`, newAges);
                                                                                                }}
                                                                                            />
                                                                                            {n}
                                                                                        </label>
                                                                                    </>
                                                                                ))}
                                                                            </div>

                                                                            {showFieldError && values.tableData[index]?.ages?.length === 0 && <div className='auth-error'>Select an age</div>}
                                                                        </td>
                                                                        {(levelDetail?._id.toLowerCase() === "teams" || levelDetail?._id.toLowerCase() === "specials") && <td >
                                                                            <div className="agesCodetd">
                                                                                {levelData.map((n) => (
                                                                                    <label key={n} className={values.tableData[index].levels.some(level => level.toLowerCase() === n.toLowerCase()) ? 'agesselected' : 'agesunselected'}>
                                                                                        <Field
                                                                                            type="checkbox"
                                                                                            name={`tableData[${index}].levels`}
                                                                                            value={n}
                                                                                            checked={values.tableData[index].levels.some(level => level.toLowerCase() === n.toLowerCase())}
                                                                                            onChange={(e) => {
                                                                                                const { checked } = e.target;
                                                                                                const newLevels = checked
                                                                                                    ? [...values.tableData[index].levels, n.toUpperCase()]
                                                                                                    : values.tableData[index].levels.filter((level) => level.toLowerCase() !== n.toLowerCase());
                                                                                                setFieldValue(`tableData[${index}].levels`, newLevels);
                                                                                            }}
                                                                                        />
                                                                                        {n}
                                                                                    </label>
                                                                                ))}
                                                                            </div>
                                                                            {showFieldError && values.tableData[index]?.levels?.length === 0 && <div className='auth-error'>Select a level</div>}
                                                                        </td>}

                                                                        <td>
                                                                            <Field name={`tableData[${index}].gender`} as="select" className="sylSlectGender">
                                                                                {genderData.map(gender => <option key={gender.value} value={gender.value}>{gender.value}</option>)}
                                                                            </Field>
                                                                        </td>
                                                                        <td>
                                                                            <Field name={`tableData[${index}].max_participants`}
                                                                                placeholder="Comp"
                                                                                type="number"
                                                                                autoComplete="off"
                                                                                onWheel={handleWheel}
                                                                                onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                                                                className={getParticipantsClassName(values, touched, index)} />
                                                                            <ErrorMessage name={`tableData[${index}].max_participants`} component="div" className='auth-error' />
                                                                        </td>

                                                                        <td>
                                                                            <Field name={`tableData[${index}].adjudicators`} as="select" onChange={(e) => handleAdjudicatorsChange(e, index, setFieldValue)} className="sylSlect">
                                                                                {AdjData.map(adj => <option key={adj.value} value={adj.value}>{adj.value}</option>)}
                                                                            </Field>
                                                                        </td>
                                                                        <td>
                                                                            <Field name={`tableData[${index}].rounds`} as="select" className="sylSlect"
                                                                                disabled={values.tableData[index].adjudicators == "9" ? true : false}
                                                                                onChange={(e) => {
                                                                                    const selectedRounds = parseInt(e.target.value);
                                                                                    let currentDancers = values?.tableData[index]?.dancerCount || []; // Current dancers array

                                                                                    if (selectedRounds === 1)
                                                                                        currentDancers = [currentDancers[0]];

                                                                                    else if (selectedRounds > 1 && currentDancers.length === 1)
                                                                                        currentDancers[1] = ({ round: 2, numberOfDancers: 2 })

                                                                                    setFieldValue(`tableData[${index}].dancerCount`, currentDancers);

                                                                                    // Always update the rounds field regardless of changes to dancers array
                                                                                    setFieldValue(`tableData[${index}].rounds`, selectedRounds);
                                                                                }}
                                                                            >
                                                                                {Rounddata.map(round => <option key={round.value} value={round.value}>{round.value}</option>)}
                                                                            </Field>
                                                                        </td>
                                                                        <td>
                                                                            <div className="d-flex flex-column gap-2">
                                                                                {values?.tableData[index]?.dancerCount?.map((roundLabel, roundIndex) => (
                                                                                    < div className="d-flex align-items-center gap-1" key={uuidv4()} >
                                                                                        <label style={{ fontWeight: 400 }}>R{roundIndex + 1}:</label>
                                                                                        <Field
                                                                                            name={`tableData[${index}].dancerCount`}
                                                                                            as="select"
                                                                                            className="sylSlect"
                                                                                            value={roundLabel.numberOfDancers}
                                                                                            onChange={(e) => {
                                                                                                const { value } = e.target;
                                                                                                setFieldValue(`tableData[${index}].dancerCount[${roundIndex}]`, { round: roundIndex + 1, numberOfDancers: Number(value) });
                                                                                            }}

                                                                                        >
                                                                                            {dancersData.map(round => (
                                                                                                <option key={round} value={round} >{round}</option>
                                                                                            ))}
                                                                                        </Field>
                                                                                    </div>
                                                                                ))}
                                                                            </div>
                                                                        </td>

                                                                        {levelDetail?._id.toLowerCase() !== "teams" && levelDetail?._id.toLowerCase() !== "specials" && <td>
                                                                            <Checkbox name={`tableData[${index}].isChecked`}
                                                                                disabled={errors.tableData && errors.tableData[index] || values.tableData[index].isChecked}
                                                                                checked={values.tableData[index].isChecked} onChange={() => handleCheckboxChange(index, values, setFieldValue)} />
                                                                        </td>}
                                                                        <td className={(levelDetail?._id.toLowerCase() === "teams" || levelDetail?._id.toLowerCase() === "specials") ? "text-center" : ""}>
                                                                            <DeleteOutlineIcon className="dltSyllabus" onClick={(e) => { deleteComp(e, index, remove, values, setFieldValue) }} />
                                                                        </td>
                                                                        <Field name={`tableData[${index}]._id`} type="text" hidden />
                                                                    </tr >
                                                                </>
                                                            )}
                                                            <button ref={addNewRef} onClick={(e) => addNewRow(e, push, values)} style={{ display: "none" }} />
                                                        </tbody>
                                                    </>
                                                )}
                                            </FieldArray>
                                        </Table>

                                    </div>
                                    <div className="d-flex gap-3 ">
                                        {<AddButton disabled={false} onClick={() => invokeAddNewRowButton(values)}>Add Competition</AddButton>}
                                        <div style={{ marginTop: "13px" }}>
                                            <button type="submit" className={!isFormEmpty(values) ? "filled py-2" : "disabled py-2"} onClick={() => saveChangesHandler(values)}> Save Changes</button>
                                        </div>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item >
                        </Accordion >}
                    </Form>
                )}

            </Formik >


        </>
    )
}
export default SyllabusTable