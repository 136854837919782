export const formatDate = (date) => {
    let dateObject = new Date(date);
    let year = dateObject?.getUTCFullYear();
    let month = dateObject?.getUTCMonth() + 1; // Months are zero-based, so add 1
    let day = dateObject?.getUTCDate();

    // Creating a new Date object with extracted values in the local time zone
    let newDateObject = new Date(year, month - 1, day);
    return newDateObject

    // date.replace("z","") 
}

export const formatAmount = (value) => {
    if (value?.toString().indexOf('.') === -1) {
        return value + '.00';
    } else {
        return value;
    }
};