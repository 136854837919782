import React, { useState, useEffect } from 'react';
import Button from '../../../../Button/Button';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import AddButton from '../../../../AddButton/AddButton';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import closeIcon from '../../../../../Assets/close-icon.svg';
import Loader from '../../../../Loader/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { SET_MODAL } from '../../../../../redux/actionTypes/modalActionType';
import { serviceConsumer } from '../../../../../network/ServiceConsumer';

const SelectOldCollaboratorModal = ({ modalState, title, btnName }) => {
    const [loading, setLoading] = useState(false);
    const [collaborators, setCollaborators] = useState([]);
    const [err, setErr] = useState("")
    const dispatch = useDispatch();
    const modal = useSelector((state) => state.modalReducer);
    const eventId = modal.eventId;
    const ManageType = localStorage.getItem('Manage');

    const getCollabName = () => {
        if (title === 'Adjudicators') return 'adjudicator'
        else if (title === 'Musicians') return 'musician'
        else if (title === 'Examiners') return 'examiner'
        else return 'sponsor'
    }

    //get collaborators on pageload
    const getCollaborators = async () => {
        const collaborator = getCollabName()
        try {
            const url = `${process.env.REACT_APP_BASE_URL}/people/${collaborator}`;
            setLoading(true);
            const response = await serviceConsumer('GET', url);
            setCollaborators(response?.people);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };
    useEffect(() => {
        getCollaborators();
    }, []);

    //initial values of formik
    const selectCollaboratorInitialValues = {
        collaborator: '',
    };

    //validation schema of formik
    const selectCollaboratorValidationSchema = Yup.object().shape({
        collaborator: Yup.string()
            .trim()
            .label('Gender:')
            .required('This field is required.')
            .nullable(),
    });

    //formik submit handler to select collaborators from old list
    const selectCollaboratorHandler = async (values) => {
        let payload = {
            peopleId: values.collaborator,
            eventId: eventId,
        };
        const collaborator = getCollabName()
        try {
            const feisUrl = `${process.env.REACT_APP_BASE_URL}/people/${collaborator}`;
            const gradeUrl = `${process.env.REACT_APP_BASE_URL}/grade-exam/${collaborator}`;
            const url = ManageType === "feis" ? feisUrl : gradeUrl
            setLoading(true);
            await serviceConsumer('POST', url, payload);
            modalCloseHandler();
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setErr(error.messages)
        }
    };

    //modal close handler
    const modalCloseHandler = () => {
        dispatch({
            type: SET_MODAL,
            payload: {
                modal: title,
                eventId: eventId,
            },
        });
    };

    //open modal to create new adjudicator
    const openAddNewCollaboratorModalHandler = () => {
        dispatch({
            type: SET_MODAL,
            payload: {
                modal: `New${title}`,
                eventId: eventId,
            },
        });
    };
    return (
        <div>
            {loading && <Loader />}
            <Modal className="addDancerScroll" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }} open={modalState}>
                <Box className="mui-box">
                    <div className="modal-box" >
                        <div className="org-modal-div">
                            <div className="close-div mt-4 mb-1">
                                <img src={closeIcon} alt="close-icon" className="close-icon" onClick={modalCloseHandler}
                                />
                            </div>
                            <p className="color-subtitle text-center mb-4">Add {title}</p>
                            <div
                                className="d-flex align-items-center justify-content-center"
                                style={{ background: 'white,borderRadius: 14px;' }}
                            >
                                <div>
                                    <Formik
                                        initialValues={selectCollaboratorInitialValues}
                                        validationSchema={selectCollaboratorValidationSchema}
                                        onSubmit={selectCollaboratorHandler}
                                    >
                                        {() => {
                                            return (
                                                <Form autoComplete="off">
                                                    <div>
                                                        <div className="d-flex flex-column">
                                                            <label className="label-head">{title}:</label>
                                                            <Field name="collaborator" as="select" className="select-box">
                                                                <option defaultValue hidden>Select</option>
                                                                {collaborators.map((collaborator) => (<option key={collaborator._id} value={collaborator._id}>{collaborator.name}</option>))}
                                                            </Field>
                                                            <ErrorMessage className="auth-error" name="collaborator" component="div" />
                                                        </div>
                                                        <div className=" mb-2">
                                                            <Button type="submit" className="outlined ">Continue</Button>
                                                        </div>
                                                        {!loading && err && (<div style={{ color: 'red', textAlign: "center" }}> {err}</div>)}
                                                        <div className="d-flex justify-content-center">
                                                            <AddButton className="plusIconbtn mb-5" onClick={openAddNewCollaboratorModalHandler}>{btnName}</AddButton>
                                                        </div>
                                                    </div>
                                                </Form>
                                            );
                                        }}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    );
};

export default SelectOldCollaboratorModal;
