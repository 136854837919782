export const routesArray = [
  "/",
  "/feis/manage",
  "/grade/manage",
  "/event-details",
  "/users",
  "/schools",
  "/feis",
  "/grade",
  "/feis/create",
  "/grade/create",
  "/previous-events",
  "/grade/create/finalGradeDetails",
  "/feis/create/finalFeisDetails",
  "/competitorss/:name",
  "/candidate/:name",
  "/edit-candidates/:name",
  "/grade/edit-register/:name",
  "/edit-competitors/:name",
  "/stripe-account",
  "/edit-register/:name",
  "/merge-split/:name",
  "/merge-split-comp/:name",
  "/email",
  "/check-in/:name" ,
  "/result-status/:name",
  "/tabulate/:name",
  "/complete-results/:name",
  "/score-checkin-download/:name",
  "/transaction-history/:name" ,
  "/published-results/:name", 
  "/awards"
];
