import { serviceConsumer } from "./ServiceConsumer";

export const getAccomodationData = async (setLoading, feisOrGrade, EventID, setaccomodationVal, initialRender) => {
    try {
        setLoading(true);
        const url = `${process.env.REACT_APP_BASE_URL}/${feisOrGrade}/accomdations/${EventID}`;
        const response = await serviceConsumer('GET', url);
        setaccomodationVal(response.Accomdations || []);
        !initialRender && setLoading(false);
        setLoading(false);

    } catch (error) {
        setLoading(true);
        !initialRender && setLoading(false);
    }
};

export const getEventDetails = async (ID, setTitleLoading, setEventName, type) => {
    try {
        setTitleLoading(true)
        if (ID) {
            const url = `${process.env.REACT_APP_BASE_URL}/${type}/${ID}`;
            const response = await serviceConsumer('GET', url);
            setEventName(response.Feis.name);
        }
        setTitleLoading(false)
    } catch (error) {
        setTitleLoading(false)
    }
};