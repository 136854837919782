import React from 'react'
import DatePicker from 'react-datepicker';
import { Field, ErrorMessage } from 'formik';
import { addYears } from 'date-fns';

const EventDate = ({ formik, props }) => {
    const maxDate = addYears(new Date(), 100);
    const dateType = props.type === "feis" ? 'feis_date' : "exam_date"

    return (
        <div className="Eventdate">
            <h4 className="eventPage mt-5">Event date</h4>
            <div className="d-flex flex-column ">
                <div className={formik.errors.opening_date && formik.touched.opening_date ? '' : 'input-control'}>
                    <label className={formik.errors.opening_date && formik.touched.opening_date ? 'label-head-org-error' : 'label-head-org'}>
                        Reg. Open Date:
                    </label>
                    <br />
                    <Field name="opening_date">
                        {({ field, form }) => (
                            <DatePicker
                                className={form.errors.opening_date && form.touched.opening_date ? 'input-box-org-date-error' : 'input-box-org-date'}
                                selected={field.value}
                                onChange={(date) => {
                                    form.setFieldValue('opening_date', date || '');
                                    if (form.values.feis_date) {
                                        formik.setFieldTouched(dateType, true);
                                        formik.validateField(dateType);
                                    }
                                    if (form.values.closing_date) {
                                        formik.setFieldTouched('closing_date', true);
                                        formik.validateField('closing_date');
                                    }
                                    setTimeout(() => { formik.validateForm(); }, 0);
                                }}
                                onBlur={() => {
                                    formik.setFieldTouched('opening_date', true);
                                    setTimeout(() => { formik.validateForm(); }, 0);
                                }}
                                minDate={new Date()}
                                maxDate={maxDate}
                                placeholderText="MM/DD/YYYY"
                            />
                        )}
                    </Field>
                    <ErrorMessage className="auth-error" name="opening_date" component="div" />
                </div>
                <div className={formik.errors.closing_date && formik.touched.closing_date ? '' : 'input-control'}>
                    <label className={formik.touched.closing_date && formik.errors.closing_date ? 'label-head-org-error' : 'label-head-org'}>
                        Reg. Close Date:
                    </label>
                    <br />
                    <Field name="closing_date">
                        {({ field, form }) => (
                            <DatePicker
                                className={formik.errors.closing_date && formik.touched.closing_date ? 'input-box-org-date-error' : 'input-box-org-date'}
                                selected={field.value}
                                onChange={(date) => {
                                    form.setFieldValue('closing_date', date || '');
                                    form.setFieldTouched('closing_date');
                                    if (form.values[dateType]) {
                                        formik.setFieldTouched(dateType, true);
                                        formik.validateField(dateType);
                                    }
                                    setTimeout(() => { formik.validateForm(); }, 0);
                                }}
                                onBlur={() => {
                                    formik.setFieldTouched('closing_date', true);
                                    setTimeout(() => { formik.validateForm(); }, 0);
                                }}
                                placeholderText="MM/DD/YYYY"
                                minDate={new Date()}
                                maxDate={maxDate}
                            />
                        )}
                    </Field>
                    <ErrorMessage className="auth-error" name="closing_date" component="div" />
                </div>
                <div className={formik.errors[dateType] && formik.touched[dateType] ? '' : 'input-control'}>
                    <label className={formik.errors[dateType] && formik.touched[dateType] ? 'label-head-org-error' : 'label-head-org'}>
                        {dateType}
                    </label>
                    <br />
                    <Field name={dateType}>
                        {({ field, form }) => (
                            <DatePicker
                                className={form.errors[dateType] && form.touched[dateType] ? 'input-box-org-date-error' : 'input-box-org-date'}
                                selected={field.value}
                                onChange={(date) => {
                                    formik.setFieldTouched(dateType, true);
                                    form.setFieldValue(dateType, date || '');
                                    formik.validateField(dateType);
                                    if (form.values.closing_date) {
                                        formik.setFieldTouched('closing_date', true);
                                        formik.validateField('closing_date');
                                    }
                                    setTimeout(() => { formik.validateForm(); }, 0);
                                }}
                                onBlur={() => formik.setFieldTouched(dateType, true)}
                                minDate={new Date()}
                                maxDate={maxDate}
                                placeholderText="MM/DD/YYYY"
                            />
                        )}
                    </Field>
                    <ErrorMessage className="auth-error" name={dateType} component="div" />
                </div>
            </div>
        </div>
    )
}

export default EventDate