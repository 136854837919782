import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import HeaderOrg from '../Headers/HeaderOrg';
import ManageTitle from '../../Components/TitleElements/ManageTitle';
import Help from '../../Components/Help/Help';
import { manageFeisArray } from './ManageFeisArray';
import { manageGradeArray } from './ManageGradeArray';
import { useDispatch, useSelector } from 'react-redux';
import { SET_MODAL } from '../../redux/actionTypes/modalActionType';
import './ManageFeisGrade.css';
import AddCompetitorModal from '../../Components/Modals/OrganizerModel/ManageFeis/Entries/AddCompetitorModal';
import AddedCompetitor from '../../Components/Modals/OrganizerModel/ManageFeis/Entries/AddedCompetitorModal';
import DirectUrlModal, { ScheduledPdf } from '../../Components/Modals/OrganizerModel/ManageFeis/Entries/DirectUrlModal';
import CollaboratorModal from '../../Components/Modals/OrganizerModel/ManageFeis/Events/CollaboratorModal';
import SelectOldCollaboratorModal from '../../Components/Modals/OrganizerModel/ManageFeis/Events/SelectOldCollaboratorModal';
import AddCollaboratorModal from '../../Components/Modals/OrganizerModel/ManageFeis/Events/AddCollaboratorModal';
import AccomodationModal from '../../Components/Modals/OrganizerModel/ManageFeis/Events/AccomodationModal';
import AccomodationModel from '../../Components/Modals/OrganizerModel/AccomodationModel';
import { serviceConsumer } from '../../network/ServiceConsumer';
import Loader from '../../Components/Loader/Loader';
import { v4 as uuidv4 } from 'uuid';
import { downloadCSV } from '../../helper/DownloadHandlers';
import { formatDate } from '../../helper/formatDate';
import { handlePdfUpload } from '../../helper/handleUploads';
import { ShowToastMessage } from '../../helper/ShowToastMessage';
import ConfirmApprovalModal from '../../Components/Modals/OrganizerModel/ConfirmApprovalModal';
import ConfirmDeleteModal from '../../Components/Modals/OrganizerModel/ConfirmDeleteModal';
import AddStagesModal from '../../Components/Modals/OrganizerModel/ManageFeis/ScheduleMaker/AddStagesModal';
import EditCollaboratorModal from '../../Components/Modals/OrganizerModel/CreateFlowCollaborator/EditCollaboratorModal';
import { getClosed } from '../../helper/setLocalStorage';

const ManageFeisGrade = () => {
  const [eventProp, setEventProp] = useState();
  const [loading, setLoading] = useState(false);
  const [apiLoading, setApiLoading] = useState(false);
  const [openApprovalModal, setOpenApprovalModal] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [isStageCreated, setIsStageCreated] = useState(false)
  const [editCollab, setEditCollab] = useState(null)
  const [isLive, setIsLive] = useState(false)
  const sylInputRef = useRef()
  const dispatch = useDispatch();
  const modal = useSelector((state) => state.modalReducer);
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const eventId = searchParams.get('id');
  const ManageType = searchParams.get("Manage")

  const type = ManageType === "feis" ? "feis" : "grade"

  const [sylErr, setsylErr] = useState(false)
  const [syllabus, setsyllabus] = useState("")
  const [uploadLoader, setUploadLoader] = useState(false)

  const url =
    ManageType === 'feis'
      ? `${process.env.REACT_APP_BASE_URL}/api/admin/feis/${eventId}`
      : `${process.env.REACT_APP_BASE_URL}/api/admin/grade/${eventId}`;

  useEffect(() => {
    getEventDetail();
  }, []);

  const getEventDetail = async () => {
    try {
      setLoading(true);

      const response = await serviceConsumer('GET', url);
      if (ManageType === 'feis') {
        setsyllabus(response?.Feis?.schedulePdfUrl)
        localStorage.setItem('page', response.Feis?.page);
        setEventProp(response.Feis);
        setIsLive(response.Feis.live)
        setIsStageCreated(response?.Feis?.isStageCreated)
      } else {
        setEventProp(response.grade_exams);
        setIsLive(response.grade_exams?.live)
        localStorage.setItem('page', response.grade_exams?.page);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    sylErr && ShowToastMessage("error", sylErr)
    setsylErr("")
  }, [sylErr])

  const downloadCsvClickHandler = async () => {
    downloadCSV(ManageType, setLoading, eventId, eventProp.name)
  };

  const eventDateString = eventProp?.eventType === 'Feiseanna' ? eventProp.feis_date : eventProp?.exam_date;//recieved as iso
  const eventDate = formatDate(eventDateString);//converted to date format
  const currDate = new Date()//to compare todays date
  eventDate.setHours(0, 0, 0, 0);
  currDate.setHours(0, 0, 0, 0);

  const enableOthers = currDate <= eventDate;//enable others only till feis date
  const disableTabulate = currDate < eventDate;//disable tabulate until feis date 

  let arr = eventProp?.eventType === 'Feiseanna' ? manageFeisArray : manageGradeArray;
  const openModal = (modalName) => {
    dispatch({
      type: SET_MODAL,
      payload: {
        modal: modalName,
        eventId: eventProp?._id,
      },
    });
  }
  const naviigationHandler = (path, name, id, tab, from) => {
    if (path && name && id) {
      path === '/tabulate' && localStorage.setItem('resultStatus', 'create');
      navigate(`${path}/${eventProp?.name}?id=${eventProp?._id}`, { state: { eventName: eventProp?.name }, });
    }
    path && !name && id && !tab && !from && navigate(`${path}?id=${eventProp?._id}`);
    path && !name && id && !tab && from && navigate(`${path}?id=${eventProp?._id}&Manage=${ManageType}`);

    if (path && tab) {
      localStorage.setItem('TAB', tab);
      localStorage.setItem('ID', eventProp?._id);
      navigate(`${path}`);
    }
  }
  //on card click of feis/grade - manage page
  const cardClickHandler = (eachCard, eventProp) => {
    const { isModal, path, name, id, modal, tab, downloadCsv, from, upload, schedule } = eachCard

    if (isModal) {
      openModal(modal)
    } else {
      naviigationHandler(path, name, id, tab, from)
      downloadCsv && downloadCsvClickHandler();
    }

    if (upload) {
      if (syllabus) openModal("ScheduledPdf")
      else sylInputRef.current.click();

    }
    if (schedule) {
      if (isStageCreated) navigate(`/manage-schedule?id=${eventProp?._id}`);
      else openModal("ScheduleMaker")
    }
  };

  //This function to upload pdf receives the link and name as args from the response of handle Pdf upload
  //The same function is used to delete and args are passed from the ScheduledPdf modal as ""
  const addScheduleToEventDetail = async (sylLink, sylName, closeModal) => {
    try {
      setUploadLoader(true);
      const payload = {
        schedulePdfUrl: sylLink,
        schedulePdfName: sylName
      }
      await serviceConsumer('PUT', url, payload);
      sylName && ShowToastMessage("success", `Schedule pdf uploaded successfully!`)
      !sylName && ShowToastMessage("success", "Schedule pdf deleted successfully!")
      setsyllabus(sylName)
      closeModal && openModal("")
      setUploadLoader(false);
    } catch (error) {
      setUploadLoader(false);
    }
  }

  const handlesylSelect = (event) => {
    handlePdfUpload(event, setsyllabus, setsylErr, setUploadLoader, "", true, addScheduleToEventDetail)
  }
  const backClickHandler = () => {
    // setTimeout(() => navigate("/admin-dashboard"), 1000)
    ManageType === "feis" ? setTimeout(() => navigate("/feis"), 1000) : setTimeout(() => navigate("/grade"), 1000)

  }

  const deleteHandler = async () => {
    setOpenDeleteModal(true)
  }

  const approveHandler = async () => {
    const url = ManageType === 'feis'
      ? `${process.env.REACT_APP_BASE_URL}/api/admin/feis/accept`
      : `${process.env.REACT_APP_BASE_URL}/api/admin/grade/accept`;
    const payload = ManageType === 'feis' ? { feisId: eventId } : { gradeId: eventId }
    try {
      setApiLoading(true)
      await serviceConsumer('POST', url, payload);
      setIsLive(true)
      setOpenApprovalModal(true)
      setApiLoading(false)
    } catch (error) {
      setApiLoading(false)
    }
  }

  const getCardClassName = (eachItem) => {
    if (getClosed()) {
      return eachItem.cardClickEnabled ? 'managecard' : "managecardDisabled"
    }
    else if (disableTabulate && eachItem?.title === "Tabulate") return "managecardDisabled"
    else if (enableOthers) return 'managecard'
    else if (eachItem.cardClickEnabled) return 'managecard'
    else return 'managecardDisabled'
  }

  const onEdit = (collab, title) => {
    openModal("")
    let from
    if (title === "Adjudicators") from = "adjucators"
    else if (title === "Musicians" || title === "Examiners") from = "musician"
    else if (title === "Sponsors") from = "sponser"
    setEditCollab({ ...collab, from: from })
  }

  const getCollabOnEdit = (from) => {
    // open the corresponding Modal
    from === "adjucators" && openModal("Adjudicators")
    from === "sponser" && openModal("Sponsors")
    if (from === "musician") {
      type === "grade" ? openModal("Examiners") : openModal("Musicians")
    }
  }
  return (
    <>
      {(loading || apiLoading || uploadLoader) && <Loader />}
      {openApprovalModal && <ConfirmApprovalModal openApprovalModal={openApprovalModal} setOpenApprovalModal={setOpenApprovalModal} />}
      {openDeleteModal && <ConfirmDeleteModal
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        eventId={eventId}
        backClickHandler={backClickHandler}
        ManageType={ManageType} />}
      <HeaderOrg />
      <ManageTitle
        eventLogo={eventProp?.logo}
        eventName={eventProp?.name}
        eventType={eventProp?.eventType}
        eventDate={eventDateString}
        loading={loading}
        onBackClick={backClickHandler}
        button="Delete"
        onClick={deleteHandler}
        button1="Approve"
        className="filled"
        className1={isLive ? "d-none" : "filled"}
        textBtnHandler={approveHandler}
      />
      <div className="db-body">
        {!loading && (
          <>
            {arr.map((item) => {
              return (
                <React.Fragment key={uuidv4()}>
                  <div className="org-body-title pt-4">{item.mainTitle}</div>
                  <div className="mt-1 hr-org-body" />
                  <div className="managecard-list mt-4">
                    {item.details.map((eachItem) => (
                      <div
                        key={uuidv4()}
                        className={getCardClassName(eachItem)}
                        style={{ columnGap: '10px' }}
                        onClick={() => cardClickHandler(eachItem, eventProp)}
                      >
                        <img className="manage-img" src={eachItem.logo} alt="" />
                        <div>
                          <div className="managecard-head">{eachItem.title}</div>
                          <div className="managecard-descr mt-1">{eachItem.description}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </React.Fragment>
              );
            })}
            <Help />
            <div style={{ display: 'none' }}>
              <input type="file" ref={sylInputRef} onChange={handlesylSelect} />
            </div>
            <div className="error mt-2">{sylErr}</div>
            <ToastContainer />

          </>
        )}
      </div>
      {modal.modal === 'AddCompetitor' && (
        <AddCompetitorModal modalState={true} />
      )}
      {modal.modal === 'AddedCompetitor' && (
        <AddedCompetitor modalState={true} />
      )}
      {modal.modal === 'DirectLink' &&
        <DirectUrlModal modalState={true} manageType={ManageType}
        />}
      {modal.modal === 'Adjudicators' && (
        <CollaboratorModal modalState={true} title="Adjudicators" collaborator="adjudicators" btnName="Add adjudicator" onEdit={onEdit} />
      )}
      {modal.modal === 'Musicians' && (
        <CollaboratorModal modalState={true} title="Musicians" collaborator="musicians" btnName="Add musician" onEdit={onEdit} />
      )}
      {modal.modal === 'Sponsors' && (
        <CollaboratorModal modalState={true} title="Sponsors" collaborator="sponsors" btnName="Add sponsor" onEdit={onEdit} />
      )}
      {modal.modal === 'Examiners' && (
        <CollaboratorModal modalState={true} title="Examiners" collaborator="examiners" btnName="Add Examiner" onEdit={onEdit} />
      )}
      {modal.modal === 'SelectOldAdjudicators' && (
        <SelectOldCollaboratorModal modalState={true} title="Adjudicators" btnName="Add new adjudicator" />
      )}
      {modal.modal === 'SelectOldExaminers' && (
        <SelectOldCollaboratorModal modalState={true} title="Examiners" btnName="Add new Examiner" />
      )}
      {modal.modal === 'SelectOldMusicians' && (
        <SelectOldCollaboratorModal modalState={true} title="Musicians" btnName="Add new musician" />
      )}
      {modal.modal === 'NewAdjudicators' && (
        <AddCollaboratorModal modalState={true} title="Adjudicator" btnName="adjudicator" />
      )}
      {modal.modal === 'NewMusicians' && (
        <AddCollaboratorModal modalState={true} title="Musician" btnName="musician" />
      )}
      {modal.modal === 'NewExaminers' && (
        <AddCollaboratorModal modalState={true} title="Examiner" btnName="Examiner" />
      )}
      {modal.modal === 'NewSponsors' && (
        <AddCollaboratorModal modalState={true} title="Sponsor" btnName="sponsor" />
      )}
      {modal.modal === 'Accomodation' && (
        <AccomodationModal modalState={true} />
      )}
      {modal.modal === 'AddAccommodation' && (
        <AccomodationModel isManage={true} showAdjucator={false} from={ManageType} />
      )}
      {editCollab && (
        <EditCollaboratorModal editCollab={editCollab} getCollabOnEdit={getCollabOnEdit} setEditCollab={setEditCollab} type={type} />
      )}
      {
        modal.modal === 'ScheduledPdf' && (
          <ScheduledPdf modalState={true} name={syllabus} deleteFn={addScheduleToEventDetail} />
        )
      }
      {
        modal.modal === 'ScheduleMaker' && (
          <AddStagesModal modalState={true} name={syllabus} deleteFn={addScheduleToEventDetail} />
        )
      }

    </>
  );
};

export default ManageFeisGrade;
