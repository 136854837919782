import React, { useState } from 'react'
import Modal from "@mui/material/Modal";
import closeIcon from "../../../Assets/close-icon.svg"
import Loader from '../../Loader/Loader';
import { serviceConsumer } from '../../../network/ServiceConsumer';


const ConfirmDeleteModal = ({ openDeleteModal, setOpenDeleteModal, eventId, backClickHandler, ManageType, }) => {
    const [loading, setLoading] = useState(false)
    const [err, setErr] = useState("")
    const [suc, setSuc] = useState("")

    const deleteHandler = async () => {
        const url = ManageType === 'feis'
            ? `${process.env.REACT_APP_BASE_URL}/api/admin/feis/delete/${eventId}`
            : `${process.env.REACT_APP_BASE_URL}/api/admin/grade/delete/${eventId}`;
        try {
            setLoading(true)
            await serviceConsumer('DELETE', url);
            setSuc("Event deleted successfully !")
            backClickHandler()
            setLoading(false)
        } catch (error) {
            setErr(error.messages)
            setLoading(false)
        }
    }
    const cancelDeleteHandler = () => {
        setOpenDeleteModal(false)
    }
    return (
        <>
            {loading && <Loader />}
            <Modal sx={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "10px" }} open={openDeleteModal}>
                <div className="org-modal-div merge-modal-div" >
                    <div className="close-div mb-1" style={{ padding: "0px 10px 0px 0px", marginLeft: "25px" }}>
                        <img src={closeIcon} alt="close-icon" className="close-icon cursor-pointer" onClick={cancelDeleteHandler} />
                    </div>
                    <div style={{ width: "250px" }}>
                        <div className="mt-3">
                            <p className="modal-org-subtitle text-center">Are you sure you want to delete this event?</p>
                        </div>
                        {err && <div style={{ color: "red", fontSize: "12px", textAlign: "center" }}>{err}</div>}
                        {suc && <div style={{ color: "#68ad3f", fontSize: "12px", textAlign: "center" }}>{suc}</div>}
                        <div className="d-flex justify-content-center gap-3 mt-2">
                            <div>
                                <button className="filled" onClick={deleteHandler} >Delete</button>
                            </div>
                            <div>
                                <button className="filled" onClick={cancelDeleteHandler}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal >
        </>
    )
}

export default ConfirmDeleteModal