export const compTableSearch = (responseArray, searchInput, setTableArr) => {
    const lowerCaseSearchInput = searchInput.toLowerCase();
    const tableArray = responseArray?.filter((searching) => {
        const dancerData = searching?.dancerData;
        const competitionData = searching?.competitonData;
        return (
            lowerCaseSearchInput === '' ||
            dancerData?.fname?.toLowerCase().includes(lowerCaseSearchInput) ||
            dancerData?.lname?.toLowerCase().includes(lowerCaseSearchInput) ||
            dancerData?.school?.toLowerCase().includes(lowerCaseSearchInput) ||
            dancerData?.birthday?.includes(lowerCaseSearchInput) ||
            searching?.card_number?.toString().includes(lowerCaseSearchInput) ||
            competitionData?.some((item) => item?.code?.toLowerCase().includes(lowerCaseSearchInput))
        );
    });
    setTableArr(tableArray);
};

export const getSortOrder = (sorter, sorterData, sortOrder) => {
    if (sorter === sorterData) {
        if (sortOrder === "asc") return "desc"
        else return "asc"
    } else return "asc"
}