import React from 'react'
import BorderColorIcon from '@mui/icons-material/BorderColor';
import AttachFileIcon from '@mui/icons-material/AttachFile';

const AboutDetails = ({ eventDetails, onEditClick }) => {
    return (
        <div className="col mainDivLive">
            <span className="eventDetailsBlock">About event</span>
            <BorderColorIcon className="editIcon" onClick={onEditClick} />
            <div className="eventsHr" style={{ marginBottom: '17px', height: '1.1px' }} ></div>

            {eventDetails?.logoName && (
                <>
                    <div className="label-head-org-file mt-4">Logo:</div>
                    <span className="fileShow"><AttachFileIcon className="attachFile" />{eventDetails?.logoName}</span>
                </>
            )}

            <div className="label-head-org-file mt-4">Syllabus File:</div>
            <span className="fileShow "><AttachFileIcon className="attachFile" />{eventDetails?.syllabus_file}</span>

            {eventDetails?.description && (
                <>
                    <div className="label-head-org-file mt-4">Description:</div>
                    <span className="goLiveDes mt-1">{eventDetails?.description}</span>
                </>
            )}

            {eventDetails?.percentageToAward && (
                <>
                    <div className="detailsHeading">Percentage to award:</div>
                    <span>{eventDetails?.percentageToAward}</span>
                </>
            )}

            {eventDetails?.minimumNumberToAward && (
                <>
                    <div className="detailsHeading">Minimum number to award: </div>
                    <span> {eventDetails?.minimumNumberToAward}</span>
                </>
            )}
        </div>
    )
}

export default AboutDetails