import React from 'react'
import Checkbox from '../../../../Components/Checkbox/Checkbox'
import "./ResultTable.css"
import { v4 as uuidv4 } from 'uuid';
import { CalculateCount } from '../../../../helper/CalculateCount'


const ThreeJudgeResult = ({ rounds, results, keyProp, event }) => {
    let judgeCount = [1, 2, 3]
    const countOfTopPlaces = event && CalculateCount(event, results)
    const getClassName = (index) => {
        if (index === countOfTopPlaces - 1) return "increasedBorderBottom"
        else return "hi"
    }
    return (
        <div style={{ width: "100%", overflowX: "scroll", marginTop: "30px", marginBottom: "50px" }} key={keyProp}>
            <table className="table my_table competitorTable">
                <tbody>
                    <tr className="header-row">
                        <td className="text-center p-25-5 w-2">Award Received</td>
                        <td className="text-center p-25-5 w-1">#</td>
                        <td className="text-center w-1">Final Place</td>
                        <td className="text-center w-3">Total Points</td>
                        <td className="mx-5 p-25-5 w-9">Competitor</td>
                        {judgeCount.map((item, index) => {
                            return <td key={uuidv4()} className="p-25-5 text-center w-9">{results[0]?.scores[index] ? results[0]?.scores[index]?.judge_details?.name : "-"}</td>
                        })}
                    </tr>
                    {rounds === 1 &&
                        <>
                            <tr style={{ background: "#EAEAEA", fontSize: "14px", height: "31px" }}>
                                <td className="text-center grey-42 p-25-5 w-2"></td>
                                <td className="text-center grey-42 p-25-5 w-1"></td>
                                <td className="w-1"></td>
                                <td className="w-3"></td>
                                <td className="mx-5 grey-42 p-25-5 w-9"></td>
                                {judgeCount.map((item, index) => {
                                    return <td key={uuidv4()} className="grey-42 p-25-5 text-center w-9">
                                        <div className="d-flex justify-content-center gap-1">
                                            <div className="col-2">1</div>
                                            <div className="col-2">Place</div>
                                            <div className="col-8">Points</div>
                                        </div>
                                    </td>
                                })}
                            </tr>
                            {results?.map((dancer, index) => {
                                return <tr className={getClassName(index)} key={uuidv4()} style={{ background: "#FFFFFF", fontSize: "12px", height: "31px" }}>
                                    <td className="text-center mx-5 py-4 w-2 grey-30"><Checkbox /></td>
                                    <td className="text-center mx-5 py-4 w-1 grey-30">{dancer?.card_number?.card_number}</td>
                                    <td className="text-center w-1">{dancer?.overallPosition}</td>
                                    <td className="text-center w-3">{dancer?.irish_points}</td>
                                    <td className="mx-5 w-9 grey-30"><div>{dancer?.dancer_fname}&nbsp;{dancer?.dancer_lname}</div><div style={{ opacity: "0.3" }}>{dancer?.school}</div></td>
                                    {judgeCount.map((item, index) => {
                                        return <td key={uuidv4()} className="w-9 grey-30 text-center">
                                            <div className="d-flex justify-content-center gap-1">
                                                <div className="col-2">{dancer?.scores[index] ? dancer?.scores[index]?.scores_by_round[0]?.score : "-"}</div>
                                                <div className="col-2">{dancer?.scores[index] ? dancer?.scores[index]?.position : "-"}</div>
                                                <div className="col-8">{dancer?.scores[index] ? dancer?.scores[index]?.irish_points : "-"}</div>
                                            </div>
                                        </td>
                                    })}
                                </tr>
                            })}
                        </>
                    }
                    {rounds === 2 &&
                        <>
                            <tr style={{ background: "#EAEAEA", fontSize: "14px", height: "31px" }}>
                                <td className="text-center grey-42 p-25-5 w-2"></td>
                                <td className="text-center grey-42 p-25-5 w-1"></td>
                                <td className="w-1"></td>
                                <td className="w-3"></td>
                                <td className="mx-5 grey-42 p-25-5 w-9"></td>
                                {judgeCount.map((item, index) => {
                                    return <td key={uuidv4()} className="grey-42 p-25-5 text-center w-9">
                                        <div className="d-flex justify-content-center gap-1">
                                            <div className="col-3">1</div>
                                            <div className="col-3">2</div>
                                            <div className="col-2">Place</div>
                                            <div className="col-4">Points</div>
                                        </div>
                                    </td>
                                })}
                            </tr>
                            {results?.map((dancer, index) => {
                                return <tr className={getClassName(index)} key={uuidv4()} style={{ background: "#FFFFFF", fontSize: "12px", height: "31px" }}>
                                    <td className="text-center mx-5 py-4 w-2 grey-30"><Checkbox /></td>
                                    <td className="text-center mx-5 py-4 w-1 grey-30">{dancer?.card_number?.card_number}</td>
                                    <td className="text-center w-1">{dancer?.overallPosition}</td>
                                    <td className="text-center w-3">{dancer?.irish_points}</td>
                                    <td className="mx-5 w-9 grey-30"><div>{dancer?.dancer_fname}&nbsp;{dancer?.dancer_lname}</div><div style={{ opacity: "0.3" }}>{dancer?.school}</div></td>
                                    {judgeCount.map((item, index) => {
                                        return <td key={uuidv4()} className="w-9 grey-30 text-center">
                                            <div className="d-flex justify-content-center gap-1">
                                                <div className="col-3">{dancer?.scores[index] ? dancer?.scores[index]?.scores_by_round[0]?.score : "-"}</div>
                                                <div className="col-3">{dancer?.scores[index] ? dancer?.scores[index]?.scores_by_round[1]?.score : "-"}</div>
                                                <div className="col-2">{dancer?.scores[index] ? dancer?.scores[index]?.position : "-"}</div>
                                                <div className="col-4">{dancer?.scores[index] ? dancer?.scores[index]?.irish_points : "-"}</div>
                                            </div>
                                        </td>
                                    })}
                                </tr>
                            })}
                        </>
                    }
                    {rounds === 3 &&
                        <>
                            <tr style={{ background: "#EAEAEA", fontSize: "14px", height: "31px" }}>
                                <td className="text-center grey-42 p-25-5 w-2"></td>
                                <td className="text-center grey-42 p-25-5 w-1"></td>
                                <td className="w-1"></td>
                                <td className="w-3"></td>
                                <td className="mx-5 grey-42 p-25-5 w-9"></td>
                                {judgeCount.map((item, index) => {
                                    return <td key={uuidv4()} className="grey-42 p-25-5 text-center w-9">
                                        <div className="d-flex justify-content-center gap-1">
                                            <div className="col-2">1</div>
                                            <div className="col-2">2</div>
                                            <div className="col-2">3</div>
                                            <div className="col-2">Place</div>
                                            <div className="col-4">Points</div>
                                        </div>
                                    </td>
                                })}
                            </tr>
                            {results?.map((dancer, index) => {
                                return <tr className={getClassName(index)} key={uuidv4()} style={{ background: "#FFFFFF", fontSize: "12px", height: "31px" }}>
                                    <td className="text-center mx-5 py-4 w-2 grey-30"><Checkbox /></td>
                                    <td className="text-center mx-5 py-4 w-1 grey-30">{dancer?.card_number?.card_number}</td>
                                    <td className="text-center w-1">{dancer?.overallPosition}</td>
                                    <td className="text-center w-3">{dancer?.irish_points}</td>
                                    <td className="mx-5 w-9 grey-30"><div>{dancer?.dancer_fname}&nbsp;{dancer?.dancer_lname}</div><div style={{ opacity: "0.3" }}>{dancer?.school}</div></td>
                                    {judgeCount.map((item, index) => {
                                        return <td key={uuidv4()} className="w-9 grey-30 text-center">
                                            <div className="d-flex justify-content-center gap-1">
                                                <div className="col-2">{dancer?.scores[index] ? dancer?.scores[index]?.scores_by_round[0]?.score : "-"}</div>
                                                <div className="col-2">{dancer?.scores[index] ? dancer?.scores[index]?.scores_by_round[1]?.score : "-"}</div>
                                                <div className="col-2">{dancer?.scores[index] ? dancer?.scores[index]?.scores_by_round[2]?.score : "-"}</div>
                                                <div className="col-2">{dancer?.scores[index] ? dancer?.scores[index]?.position : "-"}</div>
                                                <div className="col-4">{dancer?.scores[index] ? dancer?.scores[index]?.irish_points : "-"}</div>
                                            </div>
                                        </td>
                                    })}
                                </tr>
                            })}
                        </>
                    }
                </tbody>
            </table>
        </div >
    )
}

export default ThreeJudgeResult