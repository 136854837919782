export const ResetLocalStoragePagination = () => {
    localStorage.setItem("feisPage", 1)
    localStorage.setItem("prevFeisPage", 1)
    localStorage.setItem("gradePage", 1)
    localStorage.setItem("prevGradePage", 1)
}

export const setLocalForOrganizerAuth = (response) => {
    localStorage.setItem('accessToken', response.accessToken);
    localStorage.setItem('userFirstName', response.fname);
    localStorage.setItem('userSecondName', response.lname);
    localStorage.setItem("organizer", true)
}

export const setLocalOnOrgdashboardLoading = () => {
    localStorage.setItem('page', 0);
    localStorage.setItem('TAB', '1');
    localStorage.removeItem("redirectFeis")
    localStorage.removeItem("Manage")
    localStorage.removeItem("previousEvent")
}


export const setClosed = () => {
    localStorage.setItem('closed', true)
}

export const getClosed = () => localStorage.getItem('closed')