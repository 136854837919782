import React from 'react';
import { Field, ErrorMessage } from 'formik';
import TextError from './TextError';
import './style.css';

function Select(props) {
  const { label, name, options, defaultValue, showAs, disabled, ...rest } = props;

  return (
    <div className="d-flex flex-column " id="input" >
      {label ? <label htmlFor={name}>{label}</label> : ''}
      <Field as="select" id={name} name={name} disabled={disabled} {...rest}>
        <option defaultValue hidden style={{ opacity: 0.1, color: '#eaeaea' }} >
          {showAs}{' '}
        </option>
        {options?.map((option) => {
          return (
            <option
              key={option.value}
              value={option.value}
              className="options-value"
              style={{
                color: 'black',
                width: 30,
                padding: '10px',
                margin: '10px',
                height: '20px',
                display: 'table',
                border: '2px solid blue',
                outline: '2px solid blue',
              }}
            >
              {option.key}
            </option>
          );
        })}
      </Field>
      <ErrorMessage component={TextError} name={name} />
    </div>
  );
}

export default Select;
