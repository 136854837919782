import React, { useState, useEffect } from 'react'
import Checkbox from '../../Components/Checkbox/Checkbox'
import { serviceConsumer } from '../../network/ServiceConsumer'
import Loader from '../../Components/Loader/Loader'
import { v4 as uuidv4 } from "uuid"

const ParentTable = ({ userData, getUsers, serializeHelper }) => {
    const [loading, setLoading] = useState(false)
    const [schools, setSchools] = useState([])
    const [list, setList] = useState(userData?.list)

    useEffect(() => {
        getSchoolsList()
    }, [])

    useEffect(() => {
        setList(userData?.list)
    }, [userData])

    const checkBoxHandler = async (parent) => {
        const updatedList = list.map(item => {
            if (item._id === parent._id) {
                if (parent.teacher) {
                    confirmTeacherAndSchool("", parent._id, false);
                }
                return { ...item, teacher: !parent.teacher, schoolId: "" };
            }
            return item;
        });
        setList(updatedList);
    };

    //getting school data from api
    const getSchoolsList = async () => {
        const url = `${process.env.REACT_APP_BASE_URL}/account/getSchools`;
        try {
            const response = await serviceConsumer("GET", url)
            setSchools(response?.result || [])
        }
        catch (error) { }
    }

    const confirmTeacherAndSchool = async (schoolId, userId, isTeacher) => {
        const url = `${process.env.REACT_APP_BASE_URL}/api/admin/update-organizer`;
        const payload = {
            userId: userId,
            isTeacher: isTeacher,
            schoolId: schoolId
        }
        setLoading(true)
        try {
            await serviceConsumer("POST", url, payload)
            getUsers()
        }
        catch (error) { }
        setLoading(false)
    }

    return (
        <div style={{ width: "100%", overflowX: "scroll", marginTop: "30px", marginBottom: "50px" }}>
            {loading && <Loader />}
            <table className="table my_table competitorTable ">
                <tbody>
                    <tr className="header-row">
                        <td className="mx-5 w-3 p-25 text-center">S.No</td>
                        <td className="mx-5 w-8 p-25 ">First Name</td>
                        <td className="mx-5 w-8 p-25-5">Last Name</td>
                        <td className="mx-5 w-8 p-25-5">Email</td>
                        <td className="mx-5 w-10 p-25-5">Region</td>
                        <td className="w-12">Dancers</td>
                        <td className="w-5 p-25-5">Teacher</td>
                        <td className="mx-5 w-20 p-25-5">School</td>
                    </tr>
                    {list?.map((parent, index) => {
                        return <tr key={parent?._id} style={{ fontSize: "12px", height: "61px" }}>
                            <td className="mx-5 w-3 p-25 text-center">{serializeHelper + (index + 1)}</td>
                            <td className="mx-5 w-7 p-25">{parent?.fname}</td>
                            <td className="mx-5 w-7 p-25-5">{parent?.lname}</td>
                            <td className="mx-5 w-8 p-25-5">{parent?.email}</td>
                            <td className="mx-5 w-10 p-25-5">{parent?.country}</td>
                            <td className="w-12 p-25-5">
                                {parent?.dancersData?.map(dancer =>
                                    <div key={uuidv4()}>
                                        {dancer && (
                                            <>
                                                {`${dancer.fname} ${dancer.lname}`.length > 20
                                                    ? `${dancer.fname} ${dancer.lname}`.slice(0, 20) + "..."
                                                    : `${dancer.fname} ${dancer.lname}`}
                                            </>
                                        )}
                                        {/* qwertyu iop qwert */}
                                    </div>
                                )}
                            </td>
                            <td className="w-5 "><Checkbox onChange={() => checkBoxHandler(parent)} checked={parent?.teacher} /></td>

                            <td className="mx-5 w-20 p-25-5">
                                {parent?.teacher &&
                                    <select name="typeOfUser" className={parent?.teacher ? "dashboard-input" : "dashboard-input-disabled"} value={parent?.schoolId} onChange={(e) => confirmTeacherAndSchool(e.target.value, parent?._id, parent?.teacher)}>
                                        <option key="select" value="">Select School</option>
                                        {schools.map((school) => (
                                            <option key={school?._id} value={school._id}>{school.schoolName}</option>
                                        ))}
                                    </select>
                                }
                                {!parent.teacher &&
                                    <select name="typeOfUser" className="dashboard-select-disabled">
                                        <option key="select" value="">Select School</option>
                                    </select>
                                }
                            </td>
                        </tr>
                    })}
                </tbody>
            </table>
        </div>
    )
}

export default ParentTable
