export const handleWheel = (e) => {
    e.preventDefault();
    e.currentTarget.blur(); // Remove focus from the input field
};

export const handleKeyDown = (e) => {
    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
        e.preventDefault();
        e.currentTarget.blur(); // Remove focus from the input field
    }
};