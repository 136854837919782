import React, { useRef, useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { Form, Formik, Field, } from 'formik';
import FormikControl from '../../Components/formik/FormikControl';
import { serviceConsumer } from '../../network/ServiceConsumer';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Loader from '../../Components/Loader/Loader';
import { percentageData, MinNumAward } from '../../helper/OrgDataArrays';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useDispatch } from 'react-redux';
import AddButton from '../../Components/AddButton/AddButton';
import { handleLogoUpload, handlePdfUpload } from '../../helper/handleUploads';
import { accessAllTabs, accessTillAbout } from '../../helper/CreateDispatches';


const AboutFies = (props) => {
  const dispatch = useDispatch();
  const [imgError, setImgErr] = useState('');
  const [edit, setEdit] = useState(false);
  const [blogo, setblogo] = useState('');
  const [syllabusUrl, setSyllabusUrl] = useState("")
  const [sylError, setsylErr] = useState('');
  const [logo, setLogo] = useState('');
  const [syllabus, setsyllabus] = useState('');
  const [text, setText] = useState('');
  const [loading, setLoading] = useState(false);
  const [percentageAward, setPercentageAward] = useState('');
  const [minimumNumAward, setMinimumNumAward] = useState('');
  const previousEvent = localStorage.getItem('previousEvent');

  useEffect(() => {
    getEventDetails();
  }, []);

  const imgInputRef = useRef(null);
  const sylInputRef = useRef(null);

  let EventID = localStorage.getItem('ID');

  // get api on back
  const getEventDetails = async () => {
    try {
      setLoading(true);
      if (EventID) {
        const url = props.type === "feis"
          ? `${process.env.REACT_APP_BASE_URL}/api/admin/feis/${EventID}`
          : `${process.env.REACT_APP_BASE_URL}/api/admin/grade/${EventID}`;
        const response = await serviceConsumer('GET', url);
        if (response.success) {
          accessAllTabs(dispatch)
        }
        const res = props.type === "feis" ? response.Feis : response.grade_exams;
        setPercentageAward(res.percentageToAward)
        setMinimumNumAward(res.minimumNumberToAward)
        setLogo(res.logoName);
        setsyllabus(res.syllabusName)
        setSyllabusUrl(res.syllabus_file);
        setblogo(res.logo);
        setText(res.description);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleLOgo = () => {
    imgInputRef.current.click();
  };

  const handleSyllabus = () => {
    sylInputRef.current.click();
  };

  // to maintain 500 word limit
  const handleTextChange = (event) => {
    const enteredText = event.target.value;
    const words = enteredText.trim().split(/\s+/);
    if (words.length <= 500) {
      setText(enteredText);
      setEdit(true)
    }
  };

  const handleFileSelect = async (event) => {
    handleLogoUpload(event, setLogo, setblogo, setLoading, setImgErr, setEdit)
  };

  const handlesylSelect = async (event) => {
    handlePdfUpload(event, setsyllabus, setsylErr, setLoading, setEdit, false, setSyllabusUrl)
  };

  const deleteFilelogo = () => {
    setLoading(true);
    setLogo('');
    setLoading(false);
  };

  // deleting syllabus
  const deleteFilesyllabus = () => {
    setLoading(true);
    setsyllabus('');
    setEdit(true)
    setLoading(false);
  };

  // submiting the evenst
  const syllabusHandler = async (values) => {
    const val = {
      logo: blogo,
      logoName: logo,
      syllabus_file: syllabusUrl,
      syllabusName: syllabus,
      description: text,
      minimumNumberToAward: minimumNumAward,
      percentageToAward: percentageAward,
      page: localStorage.getItem('page') > 6 ? localStorage.getItem('page') : 6,
    };
    try {
      setLoading(true);
      const url = props.type === "feis"
        ? `${process.env.REACT_APP_BASE_URL}/feis/${EventID}`
        : `${process.env.REACT_APP_BASE_URL}/grade-exam/${EventID}`;
      const response = await serviceConsumer('PUT', url, val);
      if (response.success === true) {
        props.setValue('7');
        localStorage.setItem('TAB', 7);
        if (Number(localStorage.getItem('page')) < 6) {
          localStorage.setItem('page', 6);
        }
        accessTillAbout(dispatch);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  //Initial values for formik
  const venueDetailsValues = {
    logo: '',
    syllabus_file: '',
    description: '',
    percentageToAward: '',
    minimumNumberToAward: ''
  };

  const getErrorClass = (error, touched, errorClass, defaultClass) => {
    if (error && touched) return errorClass
    else return defaultClass
  }
  const getButtonClass = () => {
    if (previousEvent) return 'filled'
    else if (logo && text && edit) return 'filled'
    else return 'disabled'
  }

  return (
    <div className="AboutOrg">
      <Container className="px-0">
        {loading && <Loader />}
        <Formik initialValues={venueDetailsValues}>
          {(formik) => (
            <Form className="form form-label-right" autoComplete="off">
              <div className="syllabusMain">
                <div>
                  <div className="detailsHeaders">
                    <h4 className="eventPage">About event</h4>
                    <p className="eventDescription"> Complete the event description.</p>
                  </div>
                  <label className="label-head-org-file mt-3">Logo: </label>
                  <br />
                  {logo ? (
                    <div>
                      <div className="uploadeDIv d-flex align-items-center py-2">
                        <div className="fileIcon col-2 d-flex justify-content-center">{<AttachFileIcon />}</div>
                        <div className="col-8  flex-grow-1">Logo_Feis_{logo}</div>
                        <div className="pdfDlticon col-2 d-flex justify-content-center"><DeleteOutlineIcon onClick={deleteFilelogo} /></div>
                      </div>
                    </div>
                  ) : (

                    <>
                      <input type="file" ref={imgInputRef} style={{ display: 'none' }} onChange={handleFileSelect} />
                      <AddButton className="plusIconbtn" onClick={handleLOgo}>Upload File</AddButton>
                    </>
                  )}
                  <div className="error mt-2">{imgError}</div>
                  <label className="label-head-org-file mt-3" htmlFor="file">Syllabus File:</label>
                  <br />
                  {syllabus ? (
                    <div >
                      <div className="uploadeDIv d-flex align-items-center py-2">
                        <div className="fileIcon col-2 d-flex justify-content-center">{<AttachFileIcon />}</div>
                        <div className="col-8  flex-grow-1" style={{ wordBreak: "break-word" }}>Syllabus_Feis_{syllabus}</div>
                        <div className="pdfDlticon col-2 d-flex justify-content-center"><DeleteOutlineIcon onClick={deleteFilesyllabus} /></div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <input type="file" ref={sylInputRef} style={{ display: 'none' }} onChange={handlesylSelect} />
                      <AddButton className="plusIconbtn" onClick={handleSyllabus}>Upload File</AddButton>
                      <br />
                      <div className="error mt-2">{sylError}</div>
                    </>

                  )}

                  <div><h3 className="EventDateOrg">Description</h3> </div>
                  <div className={getErrorClass(!text, formik.touched.description, 'input-err', 'input-control')} >
                    <label className={getErrorClass(!text, formik.touched.description, 'label-head-org-file-error', 'label-head-org-file')}>Description:{' '}</label>
                    <br />
                    <Field
                      className={getErrorClass(!text, formik.touched.description, 'input-text-area-error', 'input-text-area')}
                      as="textarea"
                      name="description"
                      placeholder="Add Description"
                      value={text}
                      onChange={handleTextChange}
                    />
                  </div>
                  <div className="input-text-area-length">{!text?.length ? 500 : 500 - (text?.trim()?.split(/\s+/)?.length || 0)}</div>
                  {/* true is just added to use  the same getErrorClass function which has two conditions, else its of no value */}
                  <div className={getErrorClass(!formik.errors.percentageToAward, true, 'input-control', '')} >
                    <label
                      style={{ fontSize: '12px' }}
                      className={getErrorClass(formik.errors.percentageToAward, formik.touched.percentageToAward, 'label-head-org-error', 'org-select')}
                    >
                      Percentage to award:
                    </label>
                    <FormikControl
                      className={getErrorClass(formik.errors.percentageToAward, formik.touched.percentageToAward, 'input-box-select-error ', 'input-box-org-select')}
                      control="select"
                      name="percentageToAward"
                      showAs="Select"
                      options={percentageData}
                      id="schoolSelect"
                      value={percentageAward}
                      onChange={(e) => { setEdit(true); setPercentageAward(e.target.value) }}
                      setFieldValue={formik.setFieldValue}
                    />
                  </div>
                  <div className={!formik.errors.minimumNumberToAward ? 'input-control' : ''}>
                    <label
                      style={{ fontSize: '12px' }}
                      className={getErrorClass(formik.errors.minimumNumberToAward, formik.touched.minimumNumberToAward, 'label-head-org-error', 'org-select')}
                    >
                      Minimum number to award:
                    </label>
                    <FormikControl
                      className={getErrorClass(formik.errors.minimumNumberToAward, formik.touched.minimumNumberToAward, 'input-box-select-error', 'input-box-org-select')}
                      control="select"
                      name="minimumNumberToAward"
                      showAs="Select"
                      options={MinNumAward}
                      value={minimumNumAward}
                      id="schoolSelect"
                      setFieldValue={formik.setFieldValue}
                      onChange={(e) => { setEdit(true); setMinimumNumAward(e.target.value) }}
                    />
                  </div>
                  <div className=" mt-3 mb-5" >
                    <button
                      onClick={() => syllabusHandler(formik.values)}
                      className={getButtonClass()}
                      type="submit"
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Container>
    </div>
  );
};

export default AboutFies;
