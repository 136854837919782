import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { serviceConsumer } from '../../../../network/ServiceConsumer'
import ManageTitle from '../../../../Components/TitleElements/ManageTitle'
import HeaderOrg from '../../../Headers/HeaderOrg'
import Loader from '../../../../Components/Loader/Loader'
import Help from '../../../../Components/Help/Help'
import sort from "../../../../Assets/managefeisGrade/sort-org.svg"
import { useDispatch, useSelector } from 'react-redux'
import { SET_MODAL } from '../../../../redux/actionTypes/modalActionType'
import MergeModal from '../../../../Components/Modals/OrganizerModel/ManageFeis/Entries/MergeModal'
import SplitModal from '../../../../Components/Modals/OrganizerModel/ManageFeis/Entries/SplitModal'
import Checkbox from '../../../../Components/Checkbox/Checkbox'
import Button from '../../../../Components/Button/Button'
import { v4 as uuidv4 } from "uuid"
import { getSortOrder } from '../../../../helper/CompTableSearch'

const MergeSplitFn = () => {

    const dispatch = useDispatch()
    const modal = useSelector((state) => state.modalReducer);
    const location = useLocation()
    const navigate = useNavigate()
    const searchParams = new URLSearchParams(location.search)
    const eventId = searchParams.get('id')
    const competitionId = searchParams.get('competitionId')
    const [event, setEvent] = useState()
    const [loading, setLoading] = useState(false)
    const [searchInput, setSearchInput] = useState("")
    const [tableArr, setTableArr] = useState([])
    const [responseArray, setResponseArray] = useState([])
    const [sorterData, setSorterData] = useState("")
    const [sortOrder, setSortOrder] = useState("asc")
    const [enableButtons, setEnableButtons] = useState(false)
    const [compDetails, setCompDetails] = useState()

    //get event details for title and dancer details for table
    const getAllDetails = async () => {
        try {
            setLoading(true)
            const eventUrl = `${process.env.REACT_APP_BASE_URL}/feis/${eventId}`
            const eventResponse = await serviceConsumer('GET', eventUrl)
            setEvent(eventResponse.Feis)
            const competitorUrl = `${process.env.REACT_APP_BASE_URL}/feis/competition/dancers/${competitionId}`;
            const competitorResponse = await serviceConsumer("GET", competitorUrl)
            setCompDetails(competitorResponse.competitionDetails)
            let responseArrayWithChecked = competitorResponse.result.map(item => { return { ...item, checked: false } })
            setResponseArray(responseArrayWithChecked)
            setLoading(false)
        }
        catch (error) {
            navigate("/pageNotFound", { replace: true })
            setLoading(false)
        }
    }

    useEffect(() => {
        getAllDetails()
    }, [])

    // search input in competitors
    useEffect(() => {
        const tableArr = responseArray?.filter((searching) => {
            if (searchInput === "" ||
                searching?.dancerData?.fname?.toLowerCase().includes(searchInput?.toLowerCase())
                || searching?.dancerData?.lname?.toLowerCase().includes(searchInput?.toLowerCase())
                || searching?.dancerData?.school?.toLowerCase().includes(searchInput?.toLowerCase())
                || searching?.dancerData?.birthday?.toString().includes(searchInput)
                || searching?.card_number?.toString().includes(searchInput)
            ) {
                return searching;
            }
        })
        setTableArr(tableArr)
    }, [responseArray, searchInput])

    //sort competitor as per column
    const sortCompetitorsHandler = (sorter) => {
        let order = getSortOrder(sorter, sorterData, sortOrder);

        const tableArrCopy = [...tableArr]
        order === "asc" && sorter === "id" && tableArrCopy.sort((a, b) => a.card_number - b.card_number)
        order === "asc" && sorter === "name" && tableArrCopy.sort((a, b) => `${a.dancerData?.fname} ${a.dancerData.lname}`.localeCompare(`${b.dancerData?.fname} ${b.dancerData.lname}`))
        order === "asc" && sorter === "school" && tableArrCopy.sort((a, b) => a.dancerData.school.localeCompare(b.dancerData.school))
        order === "asc" && sorter === "dob" && tableArrCopy.sort((a, b) => (a.dancerData.birthday - (b.dancerData.birthday)))

        order === "desc" && sorter === "id" && tableArrCopy.sort((a, b) => b.card_number - a.card_number);
        order === "desc" && sorter === "name" && tableArrCopy.sort((a, b) => `${b.dancerData?.fname} ${b.dancerData.lname}`.localeCompare(`${a.dancerData?.fname} ${a.dancerData.lname}`));
        order === "desc" && sorter === "school" && tableArrCopy.sort((a, b) => b.dancerData.school.localeCompare(a.dancerData.school));
        order === "desc" && sorter === "dob" && tableArrCopy.sort((a, b) => (b.dancerData.birthday - (a.dancerData.birthday)))

        setTableArr(tableArrCopy)
        setSorterData(sorter)
        setSortOrder(order)
    }

    //checkbox handler
    function handleCheckboxChange(event, checkboxIndex, dancer) {
        let tableIndex = tableArr.findIndex((item) => item.card_number === dancer.card_number)
        let tableArrChecked = [...tableArr]
        tableArrChecked[tableIndex].checked = !tableArrChecked[tableIndex].checked
        setTableArr(tableArrChecked)
    }

    const openModalHandler = (modalName) => {
        dispatch({
            type: SET_MODAL,
            payload: {
                modal: modalName,
                eventId: event?._id,
            },
        })
    }
    useEffect(() => {
        const anyChecked = tableArr.some((item) => item.checked === true)
        anyChecked ? setEnableButtons(true) : setEnableButtons(false);
    }, [tableArr])

    return (
        <>
            {loading && <Loader />}
            <HeaderOrg />
            <ManageTitle eventLogo={event?.logo} eventName={event?.name} eventType={event?.eventType} eventDate={event?.feis_date} loading={loading} />
            <div className="db-body">
                {!loading && <>
                    <div className='org-body-title pt-4'>Merge and splits</div>
                    <div className="mb-3 hr-org-body" />
                    <div className="search-label" style={{ marginTop: "33px" }}>Search:</div>
                    <input
                        type="text"
                        name="search"
                        onChange={(e) => setSearchInput(e.target.value)}
                        placeholder="Search..."
                        className="searchFeisComp"
                    />
                    <div className="d-flex mt-4 gap-3">
                        <Button className={!enableButtons ? "disabledOutlined" : "outlined"} disabled={!enableButtons} onClick={() => openModalHandler("merge")}>Merge</Button>
                        <Button className={!enableButtons ? "disabledOutlined" : "outlined"} disabled={!enableButtons} onClick={() => openModalHandler("split")}>Split</Button>
                    </div>
                    <div className="mt-4">{compDetails?.code}-{compDetails?.description}</div>
                    <div style={{ width: "100%", overflowX: "scroll", marginTop: "5px" }}>
                        <table className="table my_table competitorTable ">
                            <tbody>
                                <tr className="header-row">
                                    <td className="mx-5" style={{ width: "15%", textAlign: "center", padding: "0.25rem 0.5rem" }}>Select&nbsp;{" "}</td>
                                    <td style={{ width: "12%", padding: "0.25rem 0.5rem" }}># &nbsp;{" "}<span>
                                        <img style={{ cursor: "pointer" }} src={sort} alt="" onClick={() => sortCompetitorsHandler("id")} />
                                    </span></td>
                                    <td style={{ width: "25%", padding: "0.25rem 0.5rem" }}>Name &nbsp;<span>
                                        <img style={{ cursor: "pointer" }} src={sort} alt="" onClick={() => sortCompetitorsHandler("name")} />
                                    </span></td>
                                    <td style={{ width: "30%", padding: "0.25rem 0.5rem" }}>School &nbsp;{" "}<span>
                                        <img style={{ cursor: "pointer" }} src={sort} alt="" onClick={() => sortCompetitorsHandler("school")} />
                                    </span></td>
                                    <td style={{ width: "18%", padding: "0.25rem 0.5rem" }}>DOB  &nbsp;{" "}<span>
                                        <img style={{ cursor: "pointer" }} src={sort} alt="" onClick={() => sortCompetitorsHandler("dob")} />
                                    </span></td>
                                </tr>
                                {tableArr?.map((dancer, index) => {
                                    return <tr key={uuidv4()} style={{ background: "#FFFFFF", fontSize: "12px", height: "40px" }}>
                                        <td className=" mx-5" style={{ width: "15%", color: "#303030", paddingLeft: "20px" }}>
                                            <Checkbox checked={dancer.checked} onChange={(event) => handleCheckboxChange(event, index, dancer)} />
                                        </td>
                                        <td className="mx-5" style={{ width: "12%", color: "#303030" }}>{dancer?.card_number}</td>
                                        <td style={{ width: "25%", color: "#303030" }}>{dancer?.dancerData?.fname}&nbsp;{dancer?.dancerData?.lname}</td>
                                        <td style={{ width: "30%", color: "#303030" }}>{dancer?.dancerData?.school}</td>
                                        <td style={{ width: "18%", color: "#303030" }}>{dancer?.dancerData?.birthday}</td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                        {!loading && responseArray?.length > 0 && tableArr?.length === 0 && searchInput !== "" && <div className="d-flex justify-content-center my-5">There are no competitors matching your search</div>}
                        {!loading && responseArray?.length === 0 && <div className="d-flex justify-content-center my-5">There are no competitors registered for this competition</div>}
                    </div>
                </>}
            </div>
            {modal.modal === "merge" && <MergeModal modalState={true} tableArr={tableArr} competitionId={competitionId} />}
            {modal.modal === "split" && <SplitModal modalState={true} tableArr={tableArr} competitionId={competitionId} />}
            <Help />
        </>
    )
}

export default MergeSplitFn