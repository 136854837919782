import React from 'react';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css'; // Import CSS for the time picker
import moment from 'moment';

const CustomTimePick = ({ selectedTime, setSelectedTime, type, disabled, setTimeError }) => {
    // Parse the selectedTime using moment
    let initialTime;
    if (selectedTime) initialTime = moment(selectedTime, 'hh:mm A')
    else if (type === "start") initialTime = moment('08:00', 'hh:mm A')
    else initialTime = moment('08:15', 'hh:mm A');

    const handleTimeChange = (time) => {
        setTimeError("")
        const formattedTime = time?.format('hh:mm A'); // Format the timeP
        setSelectedTime(formattedTime); // Pass the formatted time back to the parent component
    };

    return (
        <div>
            <TimePicker
                value={initialTime}
                onChange={handleTimeChange}
                showSecond={false} // Hide seconds dropdown
                format={'h:mm a'} // 12-hour format
                use12Hours // Use 12-hour format
                disabled={disabled}
            />
        </div>
    );
};

export default CustomTimePick;
