import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../Assets/logo-header.svg";
import LogoutModal from "../../Components/Modals/LogoutModal/LogoutModal";
import "./HeaderOrg.css";

const HeaderOrg = () => {
    const [show, setShow] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        window.scroll(0, 0);
    }, []);

    const openSignOutModalHandler = () => {
        setShow(true)
    }

    const navigateToDashboardHandler = () => {
        localStorage.setItem("eventsTab", false)
        // navigate("/admin-dashboard")
        navigate("/")
    }

    return (
        <>
            {localStorage.getItem("accessToken") &&
                <div className="px-5 py-3 bg-grey-79 d-flex justify-content-between align-items center">
                    <div><img src={logo} className="feis-logo" alt="logo" onClick={navigateToDashboardHandler} /></div>
                    <div className="text-white cursor-pointer d-flex align-items-center" onClick={openSignOutModalHandler}>Sign out</div>
                    {show && <LogoutModal setShow={setShow} show={show} />}
                </div>
            }
        </>
    );
};

export default HeaderOrg;
