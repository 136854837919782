import React from 'react'
import { Field, ErrorMessage, } from 'formik';

const CustomInput = ({ label, name, type, placeholder, formik }) => {
    return <div className={formik.errors[name] && formik.touched[name] ? 'd-grid' : 'input-control d-grid'}>
        <label className={formik.errors[name] && formik.touched[name] ? 'label-head-org-error' : 'label-head-org'}>
            {label}:
        </label>
        <Field
            className={formik.errors[name] && formik.touched[name] ? 'input-box-org-error' : 'input-box-org'}
            type={type}
            name={name}
            placeholder={placeholder}
        />
        <ErrorMessage className="auth-error" name={name} component="div" />
    </div>
}


export default CustomInput