import React from 'react'

const OrganizerTable = ({ userData, serializeHelper }) => {
    const { list } = userData
    return (
        <div style={{ width: "100%", overflowX: "scroll", marginTop: "30px", marginBottom: "50px" }}>
            <table className="table my_table competitorTable ">
                <tbody>
                    <tr className="header-row">
                        <td className="mx-5 w-10 text-center">S.No</td>
                        <td className="mx-5 w-20 p-5-25">First Name</td>
                        <td className="mx-5 w-20 p-25-5">Last Name</td>
                        <td className="mx-5 w-25 p-25-5">Email</td>
                        <td className="mx-5 w-25 p-25-5">Region</td>
                    </tr>
                    {list.map((org, index) => {
                        return <tr key={org?._id} style={{ fontSize: "12px", height: "61px" }}>
                            <td className="mx-5 w-10 text-center">{serializeHelper + (index + 1)}</td>
                            <td className="mx-5 w-10 p-5-25">{org?.fname}</td>
                            <td className="mx-5 w-10 p-25-5">{org?.lname}</td>
                            <td className="mx-5 w-25 p-25-5">{org?.email}</td>
                            <td className="mx-5 w-25 p-25-5">{org?.country}</td>
                        </tr>
                    })}
                </tbody>
            </table>
        </div>
    )
}

export default OrganizerTable
