// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.congratulations {
    background: white;
    padding: 5vh 10vh;
    border-radius: 4px;
    box-shadow: 0px 11px 46px rgba(0, 0, 0, 0.15);
    display: inline-block;
    margin: 0 auto;
    text-align: center; 
    color:#323232;
  }
  @media only screen and (min-width: 0px) and (max-width: 330px) {
    .congratulations{
        padding: 0vh !important;
    }
    }
    
    /*  <---------------------------- media query for mobile devices -------------------------------------> */
    @media only screen and (min-width: 331px) and (max-width: 650px) {
      .congratulations{
          padding: 7vh;
      }
    }
     @media only screen and (min-width: 1500px) and (max-width: 1800px) {
      .congratulations{
          width: 540px;     
      }
      
     }
     @media only screen and (min-width: 1801px) and (max-width: 24000px) {
      .congratulations{
        width: 34rem;
        padding: 60px;
      }
      
     }`, "",{"version":3,"sources":["webpack://./src/Components/success.css"],"names":[],"mappings":";AACA;IACI,iBAAiB;IACjB,iBAAiB;IACjB,kBAAkB;IAClB,6CAA6C;IAC7C,qBAAqB;IACrB,cAAc;IACd,kBAAkB;IAClB,aAAa;EACf;EACA;IACE;QACI,uBAAuB;IAC3B;IACA;;IAEA,yGAAyG;IACzG;MACE;UACI,YAAY;MAChB;IACF;KACC;MACC;UACI,YAAY;MAChB;;KAED;KACA;MACC;QACE,YAAY;QACZ,aAAa;MACf;;KAED","sourcesContent":["\n.congratulations {\n    background: white;\n    padding: 5vh 10vh;\n    border-radius: 4px;\n    box-shadow: 0px 11px 46px rgba(0, 0, 0, 0.15);\n    display: inline-block;\n    margin: 0 auto;\n    text-align: center; \n    color:#323232;\n  }\n  @media only screen and (min-width: 0px) and (max-width: 330px) {\n    .congratulations{\n        padding: 0vh !important;\n    }\n    }\n    \n    /*  <---------------------------- media query for mobile devices -------------------------------------> */\n    @media only screen and (min-width: 331px) and (max-width: 650px) {\n      .congratulations{\n          padding: 7vh;\n      }\n    }\n     @media only screen and (min-width: 1500px) and (max-width: 1800px) {\n      .congratulations{\n          width: 540px;     \n      }\n      \n     }\n     @media only screen and (min-width: 1801px) and (max-width: 24000px) {\n      .congratulations{\n        width: 34rem;\n        padding: 60px;\n      }\n      \n     }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
