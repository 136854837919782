import React, { useEffect, useState } from 'react';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import SyllabusOrg from './SyllabusOrg';
import Collaboratorsorg from './Collaboratorsorg';
import HeaderOrg from '../Headers/HeaderOrg';
import Help from '../../Components/Help/Help';
import VenueFies from '../CreateFeisEvent/VenueFies';
import FeesFies from '../CreateFeisEvent/FeesFies';
import AboutFies from '../CreateFeisEvent/AboutFies';
import EventDetailsFies from '../CreateFeisEvent/EventDetailsFies';
import CreateEventTitle from '../CreateFeisEvent/Components/CreateEventTitle';
import '../GradeEventstyle.css';
import { getEventDetails } from '../../network/CreateEventFeis';
import PaymentFies from '../CreateFeisEvent/PaymentFies';

const GradeOrg = () => {
  const params = new URLSearchParams(window.location.search);
  const [titleLoading, setTitleLoading] = useState(true)
  const [eventName, setEventName] = useState("")
  const ID = params.get('id') ? params.get('id') : localStorage.getItem("ID");
  let TabNumber = localStorage.getItem('TAB');
  let pageNumber = localStorage.getItem('page');
  const tabValue = ID && TabNumber ? TabNumber : "1"
  const [value, setValue] = useState(tabValue);

  const handleChange = (event, newValue) => {
    localStorage.setItem('TAB', newValue);
    setValue(newValue);
  };

  useEffect(() => {
    getEventDetails(ID, setTitleLoading, setEventName, "grade-exam")
    return () => {
      localStorage.removeItem('prevEvent');
    }
  }, [])


  return (
    <div>
      <HeaderOrg />
      <CreateEventTitle from="grade" eventName={eventName} titleLoading={titleLoading} ID={ID} />
      <div id="gradeMainTab">
        <TabContext value={value} style={{ padding: '0px' }} className="GradeOrgTab">
          <TabList onChange={handleChange} aria-label="lab API tabs example" className="GradeOrgTabList" >
            <Tab value="1" className="GradeOrgTabb" label="Event Details" />
            <Tab value="2" className={Number(pageNumber) + 1 >= 2 ? 'GradeOrgTabb' : 'pe-none'} label="Venue Details" />
            <Tab value="3" className={Number(pageNumber) + 1 >= 3 ? 'GradeOrgTabb' : 'pe-none'} label="Payment" />
            <Tab value="4" className={Number(pageNumber) + 1 >= 4 ? 'GradeOrgTabb' : 'pe-none'} label="Fees" />
            <Tab value="5" className={Number(pageNumber) + 1 >= 5 ? 'GradeOrgTabb' : 'pe-none'} label="Syllabus" />
            <Tab value="6" className={Number(pageNumber) + 1 >= 6 ? 'GradeOrgTabb' : 'pe-none'} label="About" />
            <Tab value="7" className={Number(pageNumber) + 1 >= 7 ? 'GradeOrgTabb' : 'pe-none'} label="Collaborators" />
          </TabList>
          <div className="hr-org-title" style={{ marginTop: '-2px' }} />
          <div className="create-feis-content">
            <TabPanel value="1" id="TabSize" className="Tabpanel">
              <EventDetailsFies setValue={setValue} setEventName={setEventName} type="grade" />
            </TabPanel>
            <TabPanel value="2" id="TabSize" >
              <VenueFies setValue={setValue} type="grade" />
            </TabPanel>
            <TabPanel value="3" id="TabSize">
              <PaymentFies setValue={setValue} type="grade" />
            </TabPanel>
            <TabPanel value="4" id="TabSize">
              <FeesFies setValue={setValue} type="grade" />
            </TabPanel>
            <TabPanel value="5" id="TabSize">
              <SyllabusOrg setValue={setValue} />
            </TabPanel>
            <TabPanel value="6" id="TabSize">
              <AboutFies setValue={setValue} type="grade" />
            </TabPanel>
            <TabPanel value="7" id="TabSize">
              <Collaboratorsorg setValue={setValue} />
            </TabPanel>
          </div>
        </TabContext>
        <Help />
      </div>
    </div>
  );
};

export default GradeOrg;
