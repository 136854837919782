import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';

export const FilledButton = (props) => {
    const { disabled, onClick, type, children } = props
    return <button
        disabled={disabled}
        onClick={onClick}
        className={'updateChangeOrg ml-3'}
        type={type}
        style={{
            width: 'auto',
            border: '2px solid',
            height: 'inherit',
            marginLeft: '24px',
            borderRadius: '4px',
            fontWeight: "500",
        }}
    >
        {children}
    </button>
}

export const OutlinedButton = (props) => {
    const { onClick, children } = props
    return <Button
        variant="outlined"
        onClick={onClick}
        color="primary"
        className="plusIconbtn"
        sx={{
            my: '20px',
            fontSize: '16px',
            marginTop: '25px',
            border: '2px solid',
            '&:hover': {
                border: '2px solid #fbb040',
                background: ' #fbb040 ',
                color: '#ffffff',
                borderRadius: '2px solid #fbb040',
            },
        }}
    >
        <span style={{ marginTop: '2px' }}>
            {children}
        </span>
    </Button>
}

export const AddButton = (props) => {
    const { onClick, disabled, children } = props

    return <Button
        onClick={onClick}
        variant="outlined"
        color="primary"
        className="plusIconbtn"
        sx={{
            my: '20px',
            fontSize: '16px',
            marginTop: disabled ? "29px" : '30px',
            padding: disabled ? "6px" : "",
            border: '2px solid',
            '&:hover': {
                border: '2px solid #fbb040',
                background: ' #fbb040 ',
                color: '#ffffff',
                borderRadius: '2px solid #fbb040',
            },
        }}
        disabled={disabled}
    >
        <AddIcon id="plusICon" />

        <span style={{ marginTop: '2px' }}>
            {children}
        </span>
    </Button>
}
