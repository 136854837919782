import React, { useState, useEffect } from 'react';
import './PaginationNew.css';
import Loader from '../Loader/Loader';
import { v4 as uuidv4 } from 'uuid';

const PaginationNew = (props) => {
  const { loading, startIndex, endIndex, totalPages, page, setPage, setStartIndex, setEndIndex, event, manageUsers } = props;
  const [arr, setArr] = useState([]);

  //create pagearr from startindex to endindex
  useEffect(() => {
    let pageArr = [];
    for (let i = startIndex; i <= endIndex; i++) {
      pageArr.push(i);
    }
    setArr(pageArr);
  }, [startIndex, endIndex]);

  //pagination handlers
  const handleNext = () => {
    startIndex + 10 <= totalPages && setStartIndex(startIndex + 10);
    endIndex + 10 <= totalPages
      ? setEndIndex(endIndex + 10)
      : setEndIndex(totalPages);
  };

  const handlePrevious = () => {
    startIndex - 10 > 1 ? setStartIndex(startIndex - 10) : setStartIndex(1);
    endIndex - 10 >= 1 && setEndIndex(Math.ceil((endIndex - 10) / 10) * 10);
  };

  const pageClickHandler = (clickedPage) => {
    setPage(clickedPage);
    localStorage.setItem(event, clickedPage)
  };
  // set startindex end index on page change
  // useEffect(() => {
  //   if (page < dataPerPage) {
  //     setStartIndex(1)
  //     totalPages > dataPerPage ? setEndIndex(10) : setEndIndex(totalPages)//problem lies here
  //   }
  // }, [totalPages])

  return (
    <>
      {loading && <Loader />}
      {totalPages > 1 && (
        <div className={manageUsers ? "d-flex justify-content-end my-0" : "d-flex justify-content-end my-5"}>
          <div className="d-flex align-items-center  pb-5 gap-1 gap-sm-2">
            <div className={startIndex === 1 ? 'prevNextDisabled web' : 'prevNext web'} onClick={handlePrevious}>
              {' '}&lt; &nbsp; Previous{' '}
            </div>
            <div className={startIndex === 1 ? 'prevNextDisabled mob' : 'prevNext mob'} onClick={handlePrevious}>
              &laquo;
            </div>
            {arr.map((item) => (
              <div key={uuidv4()} className={item == page ? 'pageItemSelected' : 'pageItem'} onClick={() => pageClickHandler(item)}>
                {item}
              </div>
            ))}
            <div className={endIndex === totalPages ? 'prevNextDisabled web' : 'prevNext web'} onClick={handleNext}>
              {' '} Next &nbsp; &gt;
            </div>
            <div className={endIndex === totalPages ? 'prevNextDisabled mob' : 'prevNext mob'} onClick={handleNext}>
              {' '}&raquo;
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PaginationNew;
