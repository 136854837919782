import React, { useState, useEffect } from 'react'
import DancerLevelData from './RegComponents/DancerLevelData';
import { formatDate } from '../../../../helper/formatDate';
import moment from 'moment';
import { getEditServiceFee, getServiceFee } from '../../../../helper/RegFlowHelpers';
const EditedDetails = (props) => {
    const {
        prevTotal,
        selectedLevelData,
        eventDetails,
        allDancer,
        deleteItemHandler,
        prevFamilyFee,
        previousCompetition,
        setEnableButton,
        setPaymentArray,
        purchaseTotal,
        setPurchaseTotal,
        serviceFee,
        setServiceFee,
    } = props;
    const [lateFee, setLateFee] = useState(0);
    const [subTotal, setSubTotal] = useState(0);
    const [count, setCount] = useState(0);
    // this total is for just reference to compare prev total with new total in edit

    useEffect(() => {
        if (eventDetails && eventDetails?.fees && eventDetails?.fees?.length > 0 && eventDetails?.fees[0]?.lateFee && eventDetails.fees[0]?.lateFee?.afterDate) {
            let lateFeeDate = moment(formatDate(eventDetails.fees[0].lateFee.afterDate));
            let currentDate = moment(formatDate(new Date()));
            if (lateFeeDate < currentDate) {
                setLateFee(eventDetails.fees[0]?.lateFee?.additionalFee);
            }
        }
    }, [eventDetails]);

    useEffect(() => {
        if (eventDetails && selectedLevelData) {
            getPaymentArray()
        }
    }, [selectedLevelData]);

    useEffect(() => {
        if (eventDetails && selectedLevelData) {
            getPurchaseTotal()
        }
    });

    const getPaymentArray = () => {
        let arr = [];
        selectedLevelData.forEach((item) => {
            let data = {};
            const amount = [];
            let dancerTotal = 0;

            if (item?.levelPriceData) {
                Object.keys(item.levelPriceData)?.forEach(level => {
                    item.levelPriceData[level]?.forEach(priceData => {
                        amount.push(priceData.price)
                        dancerTotal += priceData.price
                    })
                })
                const dancerServiceAmount = Math.round(dancerTotal / 10);
                const allLevelAmount = dancerTotal

                dancerTotal = dancerTotal + prevFamilyFee
                let serviceFee = getServiceFee(dancerTotal);
                dancerTotal = dancerTotal + serviceFee

                data.amount = amount;
                data.competitions = item?.competitions
                data.dancer_id = item?._id;
                data.dancerServiceAmount = dancerServiceAmount;
                data.dancerTotal = dancerTotal;
                data.allLevelAmount = allLevelAmount
                data.serviceFee = serviceFee
                arr.push(data);
            }
        });
        setPaymentArray(arr);
    }

    // calculate purchase total of selected event
    const getPurchaseTotal = () => {
        const { amount, newItemSelected, selected, specialAmount, amountBeforeFamilyMax } = getAmountOfLevelData();

        // update subTotal anf purchaseTotal after service fee added
        updateTotals(amount, selected, specialAmount, amountBeforeFamilyMax)

        if (!newItemSelected) {
            if (count !== previousCompetition?.length && count > 0) setEnableButton(true);
            else setEnableButton(false);
        } else {
            setEnableButton(true);
        }
    }

    // calculate purchase total of selected event
    const getAmountOfLevelData = () => {
        let amount = 0;
        let newItemSelected = false;
        let selected = false
        let selectedItem = 0

        let specialAmount = 0;
        let amountBeforeFamilyMax = 0;

        selectedLevelData?.forEach((item) => {
            if (item?.levelPriceData) {
                selected = true;
                Object.keys(item?.levelPriceData)?.forEach(level => {
                    return item?.levelPriceData[level]?.forEach((item) => {
                        amount += item.price
                        selectedItem += 1
                        if (['TEAMS', 'SPECIALS'].includes(item.group) || item.second_chance === true) {
                            specialAmount += item.price
                        }
                        if (!previousCompetition?.includes(item._id) && item) newItemSelected = true;
                    })
                });
            }
        });
        setCount(selectedItem)
        return { amount, newItemSelected, selected, specialAmount, amountBeforeFamilyMax }
    }

    const updateTotals = (value, selected, specialAmount, amountBeforeFamilyMax) => {
        setSubTotal(0)
        let amount = addServiceFee(value)
        if (selected) setPurchaseTotal(amount);
        else setPurchaseTotal(0)
    }

    // adding service fee to purchase total
    const addServiceFee = (value) => {
        let amount = value;
        const newTotal = amount - prevTotal;
        let newServiceFee = newTotal <= 0 ? 0 : getEditServiceFee(newTotal);
        setServiceFee(newServiceFee);
        amount = (newTotal) + newServiceFee
        amount = amount <= 0 ? 0 : amount
        return amount;
    }

    const getDancerTotalPrice = (dancer) => {
        let total = 0;
        Object.keys(dancer?.levelPriceData)?.forEach(level => dancer?.levelPriceData[level]?.forEach((item) => total += item.price));
        return total;
    }

    const getDanceName = (dancerId) => {
        const dancer = allDancer?.find(item => item._id === dancerId);
        return `${dancer?.fname} ${dancer?.lname}`;
    }

    return (
        <>
            {eventDetails ? (<div className=" d-flex flex-column flex-grow-1 offset-md-1 col-11 editOrg-rightDiv">
                <div className="flex-grow-1">
                    <h5 className="mt-5 pb-2 mb-3" style={{ borderBottom: "1px solid #DDD3D0 ", color: "#424242" }}> Register in:</h5>
                    {selectedLevelData?.map((dancer, index) => {
                        if (dancer?.competitions?.length) {
                            return (
                                <React.Fragment key={dancer?._id}>
                                    {dancer && (
                                        <>
                                            {dancer ? <div className="d-flex justify-content-between align-items-center py-2 my-2">
                                                <div className="dancerNameHeading">
                                                    {getDanceName(dancer._id)}
                                                </div>
                                                {dancer?.levelPriceData && <div className="dancerFeeBtn">
                                                    ${getDancerTotalPrice(dancer)}
                                                </div>}
                                            </div> : <></>}
                                            {dancer.levelPriceData && (
                                                <DancerLevelData
                                                    index={index}
                                                    dancer={dancer}
                                                    levelPriceData={dancer.levelPriceData}
                                                    deleteItemHandler={deleteItemHandler}
                                                />
                                            )}
                                        </>
                                    )}
                                </React.Fragment>
                            );
                        }
                    })}
                    <div>
                        {serviceFee > 0 && <div className="d-flex justify-content-between align-items-center">
                            <div className=" my-3 reg-name-font">Service</div>
                            <div className=" my-3 reg-amt">${serviceFee}</div>
                        </div>}
                        {lateFee > 0 && <div className="d-flex justify-content-between align-items-center">
                            <div className=" my-3 reg-name-font">LateFee</div>
                            <div className=" my-3 reg-amt">${lateFee}</div>
                        </div>}
                    </div>
                </div>
                <div>
                    <hr />

                    {subTotal && selectedLevelData?.length > 0 ? (
                        <div className="d-flex justify-content-between">
                            <div className=" my-3" style={{ fontSize: "30px", color: "#424242" }}>
                                SubTotal:
                            </div>
                            <div className=" my-3 reg-total">
                                ${subTotal}
                            </div>
                        </div>
                    ) : null}

                    <div className="d-flex justify-content-between align-items-center">
                        <div className=" my-3" style={{ fontSize: "24px", color: "#424242" }}>
                            Payable Amount:
                        </div>
                        <div className=" my-3 reg-total">
                            ${purchaseTotal && count > 0 ? purchaseTotal : 0}
                        </div>
                    </div>
                </div>
            </div>) : (
                <div>Loading...</div>
            )}
        </>
    )
}

export default EditedDetails