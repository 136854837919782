// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.searchDiv {
    background-color: white;
    border: 1px solid #797979;
    border-radius: 5px;
    color:#797979;
  }

.search,
.search:focus {
    width:100%;
    height:30px;
  border-width: 0px;
  border-radius: 5px;
  outline: 0;
  -webkit-appearance: none;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}`, "",{"version":3,"sources":["webpack://./src/Components/SearchInput.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,yBAAyB;IACzB,kBAAkB;IAClB,aAAa;EACf;;AAEF;;IAEI,UAAU;IACV,WAAW;EACb,iBAAiB;EACjB,kBAAkB;EAClB,UAAU;EACV,wBAAwB;EACxB,gBAAgB;EAChB,qBAAqB;EACrB,wBAAwB;AAC1B","sourcesContent":[".searchDiv {\n    background-color: white;\n    border: 1px solid #797979;\n    border-radius: 5px;\n    color:#797979;\n  }\n\n.search,\n.search:focus {\n    width:100%;\n    height:30px;\n  border-width: 0px;\n  border-radius: 5px;\n  outline: 0;\n  -webkit-appearance: none;\n  box-shadow: none;\n  -moz-box-shadow: none;\n  -webkit-box-shadow: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
