import React, { useRef, useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';

import closeIcon from '../../../../Assets/close-icon.svg';
import { serviceConsumer } from '../../../../network/ServiceConsumer';
import { handlePhotoUpload } from '../../../../helper/handleUploads';
import AddButton from '../../../AddButton/AddButton';
import Loader from '../../../Loader/Loader';
import CustomInput from '../CustomInput';

const AddCollabModal = ({ addOrEdit, onClose, editCollab, getList }) => {
    const [imgError, setImgErr] = useState('');
    const [logo, setLogo] = useState(editCollab?.photo);
    const [upl, setUpl] = useState('');
    const [loading, setLoading] = useState(false);
    const [photoEdited, setPhotoEdited] = useState(false);
    const imgInputRef = useRef(null);

    const handleLOgo = () => {
        imgInputRef.current.click();
    };


    //Initial values for formik
    const newCollabInitialValues = {
        name: editCollab?.name || "",
        location: editCollab?.location || "",
        photo: editCollab?.photo || "",
        type: editCollab?.type || "",
    };

    const newCollabValidationSchema = Yup.object().shape({
        name: Yup.string()
            .trim()
            .required('This field is required.')
            .max(50),
        location: Yup.string()
            .trim()
            .required('This field is required.')
            .max(50),
        type: Yup.string().required("This field is required")
    });

    const handleFileSelect = async (event) => {
        handlePhotoUpload(event, setLoading, setLogo, setUpl, setImgErr);
        setPhotoEdited(true);
    };

    const addCollab = async (values, formikHelpers) => {
        const payload = {
            name: values.name,
            location: values.location,
            type: values.type,
            photo: logo,
        };
        try {
            setLoading(true);
            const url = addOrEdit === "Add" ? `${process.env.REACT_APP_BASE_URL}/people` : `${process.env.REACT_APP_BASE_URL}/people/${editCollab?._id}`;
            addOrEdit === "Add" ? await serviceConsumer('POST', url, payload) : await serviceConsumer('PUT', url, payload);
            setLoading(false);
            getList()
            onClose()
        } catch (error) {
            console.log(error)
            setLoading(false);
        }
    };

    const closeModalHandler = () => {
        onClose()
    }

    const deleteLogoHandler = () => {
        setLogo("")
        setUpl("")
        setPhotoEdited(true);
        if (imgInputRef.current) {
            imgInputRef.current.value = null;
        }

    }

    const title = addOrEdit === "Add" ? "Add Collaborator" : "Edit Collaborator"

    const buttonName = addOrEdit == "Add" ? "Add Collaborator" : "Update"

    return (
        <>
            {loading && <Loader />}
            <Modal className="addDancerScroll" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }} open={true}>
                <Box>
                    <div style={{ maxHeight: '100vh', padding: '5px 100vw', overflow: 'scroll', }}>
                        <div className={logo ? "editCollab" : "main-div-add-adj"} style={{ height: "auto" }}>
                            <div className="close-div mt-4 mb-1">
                                <img src={closeIcon} alt="close-icon" className="close-icon" onClick={closeModalHandler} />
                            </div>
                            <p className="color-subtitle text-center mb-0">{title}</p>
                            <div className="d-flex align-items-center justify-content-center pb-5" style={{ background: 'white,borderRadius: 14px;' }}>
                                <Formik
                                    initialValues={newCollabInitialValues}
                                    validationSchema={newCollabValidationSchema}
                                    onSubmit={addCollab}
                                >
                                    {(formik) => (
                                        <Form className="form form-label-right" autoComplete="off">
                                            <CustomInput label="Full name" name="name" type="text" placeholder="Name" formik={formik} />

                                            <CustomInput label="Location" name="location" type="text" placeholder="Location" formik={formik} />
                                            <div className="d-grid">
                                                <label className="label-head-org">Type:</label>
                                                <select
                                                    name="type"
                                                    value={formik.values.type}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    className="tabulate-select"
                                                    style={{ border: "1px solid #797979" }}
                                                >
                                                    <option hidden>Select a type</option>
                                                    <option value="adjudicator">Adjudicator</option>
                                                    <option value="musician">Musician</option>
                                                    <option value="sponsor">Sponsor</option>
                                                    <option value="examiner">Examiner</option>
                                                </select>
                                                {formik.touched.type && formik.errors.type && (
                                                    <span className="error mt-2">{formik.errors.type}</span>
                                                )}
                                            </div>
                                            <input type="file" ref={imgInputRef} style={{ display: 'none' }} onChange={handleFileSelect} />

                                            <div className="d-grid">
                                                <label className="label-head-org" style={{ marginLeft: '1px' }} >Photo:</label>
                                                {logo && <img src={logo} alt="collab" style={{ width: "50px", height: "50px" }} className="mb-1" />}
                                                {logo ?
                                                    <div>
                                                        <button onClick={deleteLogoHandler} className="outlined mt-0">Delete</button>
                                                    </div>
                                                    : <div>
                                                        <AddButton onClick={handleLOgo} className="plusIconbtn mt-0 mb-2">Add Photo</AddButton>
                                                    </div>}

                                                {imgError && (<span className="error mt-2" style={{ width: '248px' }}>{imgError}</span>)}
                                                {upl && <span className="auth-suc">{upl}</span>}
                                            </div>
                                            <div className="mb-2 d-flex justify-content-center">
                                                <button className={(!formik.isValid || (!formik.dirty && !photoEdited)) ? 'disabled' : 'filled'}
                                                    style={{ fontWeight: '500', height: '42px', padding: '9px 11px', }}
                                                    type="submit"
                                                    disabled={!formik.isValid}
                                                >
                                                    {buttonName}
                                                </button>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>
        </>
    );
};


export default AddCollabModal