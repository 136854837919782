import React from 'react'
import Modal from "@mui/material/Modal";
import "../OrganizerModal.css"

const ConfirmApprovalModal = ({ openApprovalModal, setOpenApprovalModal }) => {

    const modalCloseHandler = () => {
        setOpenApprovalModal(false)
    }

    return (
        <Modal sx={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "10px" }} open={openApprovalModal}>
            <div className="org-modal-div d-flex p-5" style={{ width: "auto" }} >
                <div className="modal-org-subtitle text-center">
                    Event Approved Successfully!&nbsp; &nbsp;
                </div>
                <div className="d-flex justify-content-center gap-3 mt-4">
                    <button className="filled" onClick={modalCloseHandler}>Okay</button>
                </div>
            </div>
        </Modal >
    )
}

export default ConfirmApprovalModal