import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from "@mui/material/Modal";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useDispatch, useSelector } from 'react-redux';

import { SET_MODAL } from '../../../../../redux/actionTypes/modalActionType';
import Button from '../../../../Button/Button';
import closeIcon from "../../../../../Assets/close-icon.svg"
import { serviceConsumer } from '../../../../../network/ServiceConsumer';


const AddStagesModal = ({ modalState }) => {
    const [stageCount, setStageCount] = useState();

    const eventId = useSelector(state => state?.modalReducer?.eventId)
    const dispatch = useDispatch();
    const navigate = useNavigate()

    const stageCountsArr = [1, 2, 3, 4, 5, 6, 7, 8, 9];

    // Formik validations
    const scheduleValidationSchema = Yup.object().shape({
        stageNames: Yup.array().of(
            Yup.string().required('Stage Name is required')
        )
    });

    // Modal close handler
    const modalCloseHandler = () => {
        dispatch({
            type: SET_MODAL,
            payload: {
                modal: "",
                eventProp: "",
            }
        });
    };

    const addStagesHandler = async (values) => {
        const payloadData = values?.stageNames?.map((stageName, index) => { return { stage: index + 1, stageName: stageName } })
        const url = `${process.env.REACT_APP_BASE_URL}/schedule-maker/create-stage`
        const payload = {
            eventId: eventId,
            data: payloadData,
        }
        await serviceConsumer("POST", url, payload)
        modalCloseHandler()
        navigate(`/manage-schedule?id=${eventId}`, { state: { stages: values.stageNames } })
    }

    return (
        <Modal sx={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "10px" }} open={modalState}>
            <div className="org-modal-div split-modal-div">
                <div className="close-div mb-1">
                    <img src={closeIcon} alt="close-icon" className="close-icon" onClick={modalCloseHandler} />
                </div>
                <div className="d-flex justify-content-center">
                    <div className="split-form-div">
                        <p className="modal-org-title text-center my-2">Schedule Maker</p>
                        <p className="modal-org-subtitle split-subtitle">Add the stages</p>
                        <div className="d-flex flex-column align-items-center ">
                            <Formik
                                initialValues={{ stageNames: Array.from({ length: stageCount || 0 }, () => '') }}
                                validationSchema={scheduleValidationSchema}
                                onSubmit={(values) => addStagesHandler(values)}
                            >
                                {({ dirty, isValid }) => {
                                    const isButtonEnabled = dirty && isValid
                                    return (
                                        <Form autoComplete="off">
                                            <div>
                                                <div className="d-flex flex-column " >
                                                    <label className="label-head">Stage Count:</label>
                                                    <Field
                                                        name="stageCount"
                                                        as="select"
                                                        className="modal-select"
                                                        onChange={(e) => setStageCount(e.target.value)}
                                                        value={stageCount}
                                                    >
                                                        <option hidden>Select</option>
                                                        {stageCountsArr.map((count) => (<option value={count} key={count}>{count}</option>))}
                                                    </Field>
                                                    <ErrorMessage className="auth-error" name="compName" component="div" />
                                                </div>

                                                {/* Render input fields based on stage count */}
                                                {Array.from({ length: stageCount || 0 }, (_, i) => (
                                                    <div className="d-flex flex-column" key={i}>
                                                        <label className="label-head">Stage {i + 1}:</label>
                                                        <Field
                                                            name={`stageNames[${i}]`}
                                                            type="text"
                                                            className="modal-input"
                                                        />
                                                        <ErrorMessage name={`stageNames[${i}]`} component="div" className="auth-error" />
                                                    </div>
                                                ))}

                                                <div className="d-flex flex-grow-1 justify-content-center mt-3">
                                                    <Button type="submit" disabled={!isButtonEnabled} className={isButtonEnabled ? "filled mb-5" : "disabled mb-5"} > Add Stages</Button>
                                                </div>
                                            </div>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </Modal >
    );
};

export default AddStagesModal;
