import React, { useState } from 'react'
import closeIcon from "../../../Assets/close-icon.svg"
import { Modal } from '@mui/material'
import Loader from '../../Loader/Loader'
import { serviceConsumer } from '../../../network/ServiceConsumer'
import { ShowToastMessage } from '../../../helper/ShowToastMessage'

const AddSchoolModal = ({ modalState, setOpenSchool, getSchools }) => {
    const [schoolName, setSchoolName] = useState("")
    const [loading, setLoading] = useState(false)

    const addSchoolHandler = async () => {
        try {
            setLoading(true)
            const url = `${process.env.REACT_APP_BASE_URL}/school`;
            const payload = { schoolName: schoolName }
            await serviceConsumer("POST", url, payload)
            ShowToastMessage("success", "School added successfully")
            getSchools()
            modalCloseHandler()
            setLoading(false)
        } catch (err) {
            setLoading(false)
            ShowToastMessage("error", "Something went wrong")
        }
    }

    const modalCloseHandler = () => setOpenSchool(false)
    return (
        <Modal sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px', }} open={modalState}>
            <div className="org-modal-div">
                {loading && <Loader />}
                <div className="close-div mb-1">
                    <img src={closeIcon} alt="close-icon" className="close-icon" onClick={modalCloseHandler} />
                </div>
                <p className="modal-org-title text-center ">Add School</p>
                <div className="d-flex align-items-center justify-content-center addCompetitor" style={{ background: 'white,borderRadius: 14px;' }}>
                    <input className="modal-input" placeholder="School" onChange={(e) => setSchoolName(e.target.value)} value={schoolName} />
                </div>
                <div className="d-flex justify-content-center my-5">
                    <button className='filled' onClick={addSchoolHandler}>Save</button>
                </div>
            </div>
        </Modal >
    )
}

export default AddSchoolModal
