import React, { useState, useEffect } from 'react'
import Modal from "@mui/material/Modal";
import closeIcon from "../../../../../Assets/close-icon.svg"
import { useDispatch, useSelector } from 'react-redux';
import { SET_MODAL } from '../../../../../redux/actionTypes/modalActionType';
import Button from '../../../../Button/Button';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { serviceConsumer } from '../../../../../network/ServiceConsumer';
import Loader from "../../../../Loader/Loader";
import { groupAndSortComp } from '../../../../../helper/SortSyllabus';

const MergeModal = ({ modalState, tableArr, competitionId }) => {

    const dispatch = useDispatch();
    const [competitions, setCompetitions] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")
    const [success, setSuccess] = useState("")
    const modal = useSelector((state) => state.modalReducer);

    // get competition details for selectvalues
    const getCompetitions = async () => {
        try {
            const url = `${process.env.REACT_APP_BASE_URL}/feis/${modal.eventId}/competition`
            setLoading(true)
            const response = await serviceConsumer("GET", url)
            const sortedCompetitions = groupAndSortComp(response.competitions)
            setCompetitions(sortedCompetitions)
            setLoading(false)
        } catch (error) {
            setError(error.messages)
            setLoading(false)
        }
    }
    useEffect(() => {
        getCompetitions()
    }, [])
    //formik initial values
    const selectCompetitorInitialValues = {
        competition: ""
    }

    //formik validations
    const selectCompetitorValidationSchema = Yup.object().shape({
        competition: Yup.string()
            .trim()
            .label("Gender:")
            .required("This field is required.")
            .nullable(),
    })

    //formik submit handler
    const selectCompetitorHandler = async (values) => {
        const checkedArray = tableArr.filter((item) => item.checked)
        const registrationIds = checkedArray.map((item => item._id))
        const payload = {
            origin_competition: competitionId,
            destination_competition: values?.competition,
            registrations: registrationIds,
        }
        try {
            const url = `${process.env.REACT_APP_BASE_URL}/feis/competition/merge`
            setLoading(true)
            await serviceConsumer("POST", url, payload)
            setSuccess("Competitors merged successfully")
            setTimeout(() => window.location.reload(), 1000)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            setError(error.messages)
        }
    }

    //modal close handler
    const modalCloseHandler = () => {
        dispatch({
            type: SET_MODAL,
            payload: {
                modal: "",
                eventProp: "",
            }
        })
    }

    const changeHandler = (e, name, setFieldValue) => {
        setFieldValue(name, e.target.value);
        setError("");
        setSuccess("")
    };

    return (
        <>
            {loading && <Loader />}
            <Modal sx={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "10px" }} open={modalState}>
                <div className="org-modal-div merge-modal-div" >
                    <div className="close-div mb-1" style={{ padding: "0px 10px 0px 0px" }}>
                        <img src={closeIcon} alt="close-icon" className="close-icon" onClick={modalCloseHandler} />
                    </div>
                    <div style={{ width: "250px" }}>
                        <p className="modal-org-title text-center my-2">Merge into</p>
                        <p className="modal-org-subtitle text-center">Merge dancers with other competition.</p>
                        <div className="d-flex flex-column align-items-center ">
                            <Formik
                                initialValues={selectCompetitorInitialValues}
                                validationSchema={selectCompetitorValidationSchema}
                                onSubmit={selectCompetitorHandler}
                            >
                                {({ setFieldValue, dirty, isValid }) => {
                                    return (
                                        <Form autoComplete="off">
                                            <div>
                                                <div className="d-flex flex-column">
                                                    <label className="label-head">Choose competition:</label>
                                                    <Field name="competition" as="select" className="select-box" onChange={(e) => {
                                                        changeHandler(e, "competition", setFieldValue);
                                                    }} >
                                                        <option defaultValue hidden >Select</option>
                                                        {competitions.map(competition => <option key={competition?._id} value={competition?._id}>{competition?.code}-{competition?.description}-({competition?._id})</option>)}
                                                    </Field>
                                                    <ErrorMessage className="auth-error" name="competition" component="div" />
                                                </div>
                                                {success && <div style={{ color: "green", fontSize: "12px" }}>{success}</div>}
                                                {error && <div style={{ color: "red", fontSize: "12px" }}>{error}</div>}
                                                <div className="d-flex flex-grow-1 justify-content-center mt-3">
                                                    <Button type="submit" className={isValid && dirty ? "filled" : "disabled"} > Merge</Button>
                                                </div>
                                            </div>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        </div>
                    </div>
                </div>
            </Modal >
        </>
    )
}

export default MergeModal