import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SearchInput from "../../Components/SearchInput";
import SchoolTable from "./SchoolTable";
import SingleLineTitle from "../../Components/TitleElements/SingleLineTitle";
import AddButton from "../../Components/AddButton/AddButton";
import AddSchoolModal from "../../Components/Modals/OrganizerModel/AddSchoolModal";
import { serviceConsumer } from "../../network/ServiceConsumer";
import PaginationNew from "../../Components/Pagination/PaginationNew";
import Loader from "../../Components/Loader/Loader";

const ManageSchools = () => {
    const [searchInput, setSearchInput] = useState("")
    const [openSchool, setOpenSchool] = useState(false)
    const [list, setList] = useState([])
    const [loading, setLoading] = useState(false)
    //data for pagination
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(0);
    const [filter, setFilter] = useState("oldest")


    useEffect(() => {
        getSchools()
    }, [searchInput, page, filter])
    useEffect(() => {
        setPage(1)
    }, [searchInput, filter])

    const getSchools = async () => {
        try {
            setLoading(true)
            const url = `${process.env.REACT_APP_BASE_URL}/school?page=${page}&limit=10&search=${searchInput}&sort=${filter}`;
            const response = await serviceConsumer("GET", url)
            setList(response?.result?.list)
            setPaginationDetail(response.result?.resultCount)
            setLoading(false)
        } catch (err) {
            setLoading(false)
        }
    }
    const setPaginationDetail = (totalData) => {
        let totalPage = Math.ceil(Number(totalData / 10));
        setTotalPages(totalPage);
        let startindex = page % 10 === 0 ? page - 9 : Math.floor(page / 10) * 10 + 1;
        setStartIndex(startindex);
        startindex + 9 > totalPage ? setEndIndex(totalPage) : setEndIndex(startindex + 9);
    }

    const navigate = useNavigate()

    const backToDashboardHandler = () => {
        localStorage.setItem("eventsTab", false)
        navigate("/")
    }

    const openSchoolModal = () => {
        setOpenSchool(true)
    }

    return (
        <>
            {loading && <Loader />}
            <SingleLineTitle titleName="Schools" onBackClick={backToDashboardHandler} />
            <div className="db-body">
                <div className="d-flex flex-column flex-md-row justify-content-between align-items-md-center pt-3">
                    <div className=" col-sm-8 col-md-4 my-1 my-md-2">
                        <SearchInput searchInput={searchInput} setSearchInput={setSearchInput} />
                    </div>
                    <div className="col-sm-8 col-md-3 my-1 my-md-2 d-flex justify-content-md-end">
                        <AddButton onClick={openSchoolModal}>Add School</AddButton>
                    </div>
                </div>
                <SchoolTable list={list} setList={setList} page={page} getSchools={getSchools} filter={filter} setFilter={setFilter} />
                {openSchool && <AddSchoolModal modalState={true} setOpenSchool={setOpenSchool} getSchools={getSchools} />}
                <PaginationNew
                    loading={loading}
                    startIndex={startIndex}
                    endIndex={endIndex}
                    totalPages={totalPages}
                    page={page}
                    setPage={(val) => setPage(val)}
                    setStartIndex={(val) => setStartIndex(val)}
                    setEndIndex={(val) => setEndIndex(val)}
                    dataPerPage={10}
                    manageUsers
                />
            </div>
        </>
    );
};

export default ManageSchools;
