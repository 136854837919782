import React, { useState, useEffect } from 'react';
import { Form, Formik, } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import AccomodationModel from '../../Components/Modals/OrganizerModel/AccomodationModel';
import MainDiv from '../../Components/AddedAdjdiv/MainDiv';
import Loader from '../../Components/Loader/Loader';
import AccomodationDiv from '../../Components/AddedAdjdiv/AccomodationDiv';
import AddButton from '../../Components/AddButton/AddButton';
import { accessTillSyllabus } from '../../helper/CreateDispatches';
import { serviceConsumer } from '../../network/ServiceConsumer';
import SelectCollaborators from '../../Components/Modals/OrganizerModel/CreateFlowCollaborator/SelectCollaborators';
import NewCollaboratorModal from '../../Components/Modals/OrganizerModel/CreateFlowCollaborator/NewCollaboratorModal';
import { getAccomodationData } from '../../network/CreateEventFeis';
import EditCollaboratorModal from '../../Components/Modals/OrganizerModel/CreateFlowCollaborator/EditCollaboratorModal';

//all musicians mentioned represents examiners
const Collaboratorsorg = () => {
  const [openCollabModal, setOpenCollabModal] = useState("")
  const [openNewCollabModal, setOpenNewCollabModal] = useState("")
  const [openModalAccomodation, setopenModelAccomodation] = useState(false);
  const [sponserIds, setSponsersIds] = useState([]);
  const [musicianId, setMusicianId] = useState([]);
  const [mus, setmus] = useState([]);
  const [spn, setspn] = useState([]);
  const [loading, setLoading] = useState();
  const [acccmodataionVal, setaccomodationVal] = useState([]);
  const [initialRender, setInitialRender] = useState(true);
  const [editCollab, setEditCollab] = useState(null)
  const showAdjucator = false;
  let EventID = localStorage.getItem('ID');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    accessTillSyllabus(dispatch);
    setInitialRender(false);
    setLoading(true);
    Promise.all([getCollabs('examiners', setmus, setMusicianId), getCollabs('sponsors', setspn, setSponsersIds), getAccomodationData(setLoading, "grade-exam", EventID, setaccomodationVal, initialRender),])
      .then((res) => setLoading(false))
      .catch((err) => setLoading(false));
  }, []);

  const handleCollabModals = (collabType) => {
    collabType === "sponsor" && setOpenNewCollabModal(collabType)
    setOpenCollabModal(collabType);
  }

  const handleOpenModelAccomodation = () => {
    setopenModelAccomodation(!openModalAccomodation);
  };

  const getCollabs = async (collab, setCollab, setCollabIds) => {
    try {
      !initialRender && setLoading(true);
      const url = `${process.env.REACT_APP_BASE_URL}/grade-exam/${EventID}/${collab}`;
      const response = await serviceConsumer('GET', url);
      setCollab(response.people);
      setCollabIds(response.people.map((men) => men._id));
      !initialRender && setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  const getCollabOnEdit = (from) => {
    from === "musician" ? getCollabs('examiners', setmus, setMusicianId) : getCollabs('sponsors', setspn, setSponsersIds);
    setLoading(true);
  }


  const onDelete = async (id, from) => {
    try {
      if (from === 'musician' || from === "sponser") {
        const url = `${process.env.REACT_APP_BASE_URL}/people/${id}/deletegrade/${EventID}`;
        await serviceConsumer('DELETE', url);
        from === "musician" ? getCollabs('examiners', setmus, setMusicianId) : getCollabs('sponsors', setspn, setSponsersIds);
        setLoading(true);
      } else if (from === 'acccmodataion') {
        setLoading(true);
        if (EventID) {
          const url = `${process.env.REACT_APP_BASE_URL}/grade-exam/${EventID}/del/accomdations/${id}`;
          await serviceConsumer('DELETE', url);
          getAccomodationData(setLoading, "grade-exam", EventID, setaccomodationVal, initialRender);
        }
        setLoading(false);
      }
    }
    catch (error) {
      setLoading(false);
    }
  };
  const onEdit = (collabDetail, from) => {
    setEditCollab({ ...collabDetail, from: from })
  }

  const finishHandler = async () => {
    let val = {
      sponsors: sponserIds.filter((item) => item != null),
      examiners: musicianId.filter((item) => item != null),
      page: 7,
    };
    try {
      setLoading(true);
      const url = `${process.env.REACT_APP_BASE_URL}/grade-exam/${EventID}`;
      const response = await serviceConsumer('PUT', url, val);
      if (response.success === true) {
        localStorage.setItem('page', 7);
        localStorage.removeItem('previousEvent');
        navigate('/grade/create/finalGradeDetails');
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const collaboratorInitialValues = {};
  return (
    <div className="AboutOrg">
      <div>
        {loading && <Loader />}
        <Formik initialValues={collaboratorInitialValues}>
          {(formik) => (
            <Form className="form form-label-right" autoComplete="off">
              <div className="syllabusMain">
                <div className="">
                  <div className="detailsHeaders">
                    <h4 className="eventPage">Complete collaborators</h4>
                    <p className="eventDescription"> Examiners, Sponsors, And others.</p>
                  </div>
                  <label className="eventGradeColabLabel ">Examiners:</label>
                  <br />
                  {mus?.length > 0 ? renderPeopleData(mus, "musician", onDelete, onEdit) : <div>No Examiners</div>}
                  <AddButton className="plusIconbtn" onClick={() => handleCollabModals("examiner")} from="collab">Add</AddButton>
                  <br />
                  <label className="eventGradeColabLabel mt-3">Accomodations:</label>
                  <br />
                  {acccmodataionVal?.length > 0 ? acccmodataionVal?.map((accomodation) =>
                    <AccomodationDiv
                      name={accomodation?.name}
                      photo={accomodation?.photo}
                      country={accomodation?.country}
                      from={'acccmodataion'}
                      onDelete={onDelete}
                      id={accomodation._id}
                      key={accomodation._id}
                    />
                  ) : <div>No accommodations</div>}
                  {acccmodataionVal?.length < 5 &&
                    <AddButton className="plusIconbtn" onClick={handleOpenModelAccomodation} from="collab"> Add</AddButton>
                  }
                  <br />
                  <label className="eventGradeColabLabel mt-3">Sponsors:</label>
                  <br />
                  {spn?.length > 0 ? renderPeopleData(spn, "sponser", onDelete, onEdit) : <div>No sponsors</div>}
                  <AddButton className="plusIconbtn" onClick={() => handleCollabModals("sponsor")} from="collab">Add</AddButton>
                  <button onClick={finishHandler} className="filled mt-4 mb-5 d-block" >Finish</button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      {openCollabModal &&
        <SelectCollaborators
          setOpenCollabModal={setOpenCollabModal}
          openCollabModal={openCollabModal}
          setOpenNewCollabModal={setOpenNewCollabModal}
          getCollab={() => getCollabs('examiners', setmus, setMusicianId)} />}

      {openNewCollabModal &&
        <NewCollaboratorModal
          setOpenCollabModal={setOpenCollabModal}
          setOpenNewCollabModal={setOpenNewCollabModal}
          openNewCollabModal={openNewCollabModal}
          from="grade"
          getCollab={openNewCollabModal === "examiner" ? () => getCollabs('examiners', setmus, setMusicianId) : () => getCollabs('sponsors', setspn, setSponsersIds)} />}

      {openModalAccomodation && (
        <AccomodationModel
          modalState={openModalAccomodation}
          action={handleOpenModelAccomodation}
          showAdjucator={showAdjucator}
          accomodationData={() => getAccomodationData(setLoading, "grade-exam", EventID, setaccomodationVal, initialRender)}
          from={'grade'}
        />
      )}
      {editCollab && <EditCollaboratorModal editCollab={editCollab} setEditCollab={setEditCollab} getCollabOnEdit={getCollabOnEdit} type={"grade"} />}

    </div>
  );
};

export default Collaboratorsorg;

const renderPeopleData = (people, from, onDelete, onEdit) => {
  return people?.map((item) =>
    <MainDiv
      key={item?._id}
      name={item?.name}
      location={item?.location}
      photo={item?.photo}
      from={from}
      id={item._id}
      onDelete={onDelete}
      item={item}
      onEdit={onEdit}
    />
  )
}
