import React from 'react';
import './pagenotfound.css';
import { ToastContainer } from 'react-toastify';

export const PageNotFound = () => {
  const navigateHome = () => {
    window.history.go(-1);
  };

  return (
    <>
      <center style={{ padding: '5px' }}>
        <div className="errorui">
          <div className="errornum">
            <h1>404</h1>
          </div>
          <div className="errorContent">
            <h5>
              {' '}
              Oops! The page you are looking for seems to be missing. <br />
              Please check the URL or return to the homepage
            </h5>
            <button onClick={navigateHome}>Back</button>
          </div>
        </div>
        <ToastContainer />;
      </center>
    </>
  );
};
