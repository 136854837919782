import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ManageTitle from '../../../../Components/TitleElements/ManageTitle';
import HeaderOrg from '../../../Headers/HeaderOrg';
import Help from '../../../../Components/Help/Help';
import Loader from '../../../../Components/Loader/Loader';
import { serviceConsumer } from '../../../../network/ServiceConsumer';
import OneJudgeResult from './OneJudgeResult';
import ThreeJudgeResult from './ThreeJudgeResult';
import NineJudgeResult from './NineJudgeResult';
import { ToastContainer } from 'react-toastify';
import { ShowToastMessage } from '../../../../helper/ShowToastMessage';
import { getPrintHandler } from '../../../../helper/printHandlers';

const CompleteResults = () => {
    const [event, setEvent] = useState()
    const [results, setResults] = useState([])
    const [competitionCode, setCompetitionCode] = useState("")
    const [competitionId, setCompetitionId] = useState("")
    const [loading, setLoading] = useState(false)
    const [isPublished, setIsPublished] = useState(false)
    const [adjudicatorCount, setAdjudicatorCount] = useState(0)
    const [rounds, setRounds] = useState(0)
    const [buttonEnabled, setButtonEnabled] = useState(false)
    const tableDataKey = 0
    const [initialLoading, setInitialLoading] = useState(false)
    const [competitionGroup, setCompetitionGroup] = useState("")
    const location = useLocation()
    const navigate = useNavigate()
    const searchParams = new URLSearchParams(location.search)
    const eventId = searchParams.get('id')
    const compId = searchParams.get('compId')

    useEffect(() => {
        getInitialDetails();
    }, []);

    useEffect(() => {
        let scoreCount = 0;
        results.forEach(result => (result.scores.forEach(scoreObj => scoreCount += scoreObj.scores_by_round.length)))
        const enabled = adjudicatorCount === 9 ? (adjudicatorCount * results.length) === scoreCount : (rounds * adjudicatorCount * results.length) === scoreCount
        setButtonEnabled(enabled)
    }, [adjudicatorCount, results, rounds])

    const getInitialDetails = async () => {
        try {
            setInitialLoading(true)
            const eventUrl = `${process.env.REACT_APP_BASE_URL}/feis/${eventId}`
            const eventResponse = await serviceConsumer('GET', eventUrl)
            setEvent(eventResponse.Feis)

            const currentCompUrl = `${process.env.REACT_APP_BASE_URL}/feis/competition/${compId}`
            const currentCompResponse = await serviceConsumer("GET", currentCompUrl)
            setCompetitionDetails(currentCompResponse.competition)

            const resultsUrl = `${process.env.REACT_APP_BASE_URL}/feis/competition/scores/${currentCompResponse.competition._id}`;
            const resultsResponse = await serviceConsumer("GET", resultsUrl)
            setResults(resultsResponse.results)
            setInitialLoading(false)
        } catch (error) {
            navigate("/pageNotFound", { replace: true })
            setInitialLoading(false)
        }
    };

    const setCompetitionDetails = (selectedCompetition) => {
        setCompetitionGroup(selectedCompetition?.group)
        setCompetitionCode(selectedCompetition?.code)
        setCompetitionId(selectedCompetition?._id)
        setRounds(selectedCompetition?.rounds)
        setAdjudicatorCount(selectedCompetition?.adjudicators)
        setIsPublished(selectedCompetition?.results_published)
    }
    //print card handler
    const printHandler = async () => {
        const url = `${process.env.REACT_APP_BASE_URL}/feis/results/pdf/org/${competitionId}`;
        getPrintHandler(url, setLoading)
    }

    //publish or unpulish results
    const publishOrUnpublishHandler = async () => {
        try {
            setLoading(true)
            const publishUrl = `${process.env.REACT_APP_BASE_URL}/feis/competition/scores/publish/${competitionId}`
            const unpublishUrl = `${process.env.REACT_APP_BASE_URL}/feis/competition/scores/unpublish/${competitionId}`
            const url = isPublished ? unpublishUrl : publishUrl;
            const response = await serviceConsumer("POST", url)
            isPublished ? setIsPublished(false) : setIsPublished(true)
            setLoading(false)
            ShowToastMessage("success", response.message)
        } catch (error) {
            ShowToastMessage("error", error.messages)
            setLoading(false)
        }
    }
    const getButtonClass = () => {
        if (isPublished) return "outlined"
        else if (buttonEnabled) return "filled"
        else return "disabled"
    }

    return (
        <>
            {loading && <Loader />}
            {initialLoading && <Loader />}
            <HeaderOrg />
            {!initialLoading && <ManageTitle
                eventLogo={event?.logo}
                eventName={event?.name}
                eventType={event?.eventType}
                eventDate={event?.feis_date}
                button={isPublished ? "Unpublish" : "Publish Results"}
                className={getButtonClass()}
                onClick={publishOrUnpublishHandler}
                textButton="Print"
                textBtnClass={buttonEnabled ? "text-btn" : "text-btn-disabled"}
                textBtnHandler={printHandler} />}

            <div className="org-body-white">
                {!initialLoading && <>
                    <div className="org-body-title pt-4">Complete results</div>
                    <div className="mt-1 hr-org-body"></div>
                    <div className="d-md-flex mt-4 gap-md-4">
                        <div>
                            <div className="search-label">Level:</div>
                            <input name="level" className="check-in-input" value={competitionGroup} readOnly />
                        </div>
                        <div className='mt-4 mt-md-0'>
                            <div className="search-label">Competition:</div>
                            <input name="level" className="check-in-input" value={`${competitionCode}-${competitionGroup}`} readOnly />
                        </div>
                    </div >
                    {adjudicatorCount === 1 && <OneJudgeResult rounds={rounds} results={results} keyProp={tableDataKey} event={event} />}
                    {adjudicatorCount === 3 && <ThreeJudgeResult rounds={rounds} results={results} keyProp={tableDataKey} event={event} />}
                    {adjudicatorCount === 9 && <NineJudgeResult results={results} keyProp={tableDataKey} event={event} />}
                </>}
            </div >
            <ToastContainer />
            <Help />
        </>
    )
}

export default CompleteResults