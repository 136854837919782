import {  ErrorMessage, useFormikContext } from 'formik';
import DatePicker from 'react-datepicker';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const DatePickerField = ({ field, form, ...rest }) => {
  const { setFieldValue } = useFormikContext();
  const { name, value } = field;
  const { errors, touched } = form;
  const error = errors[name];
  const touch = touched[name];

  const handleChange = (date) => {
    setFieldValue(name, date);
  };

  return (
    <div className="date-picker-field">
      <DatePicker
        {...field}
        {...rest}
        selected={value ? new Date(value) : null}
        onChange={handleChange}
        className={`input-box-org-date ${
          error && touch ? 'input-box-org-date-error' : ''
        }`}
      />
      <span className="calendar-icon">
        <FontAwesomeIcon icon={faCalendarAlt} />
      </span>
      <ErrorMessage
        name={name}
        component="div"
        className="error-message"
      />
    </div>
  );
};
export default DatePickerField;
