import React, { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import closeIcon from '../../../../../Assets/close-icon.svg';
import { useDispatch, useSelector } from 'react-redux';
import { SET_MODAL } from '../../../../../redux/actionTypes/modalActionType';
import Loader from '../../../../Loader/Loader';
import { serviceConsumer } from '../../../../../network/ServiceConsumer';
import Box from '@mui/material/Box';
import photoPlaceholder from '../../../../../Assets/photoPlaceholder.png';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddButton from '../../../../AddButton/AddButton';
import { getClosed } from '../../../../../helper/setLocalStorage';

const AccomodationModal = ({ modalState }) => {
    const [loading, setLoading] = useState(false);
    const [accommodations, setAccommodations] = useState([]);
    const [errDelay, setErrDelay] = useState(false);
    const [err, setErr] = useState('');
    const dispatch = useDispatch();
    const modal = useSelector((state) => state.modalReducer);
    const eventId = modal.eventId;
    const ManageType = localStorage.getItem('Manage');


    //get all accommodations on page load
    const getAllAccommodations = async () => {
        try {
            const url =
                ManageType.trim() == 'feis'
                    ? `${process.env.REACT_APP_BASE_URL}/feis/accomdations/${eventId}`
                    : `${process.env.REACT_APP_BASE_URL}/grade-exam/accomdations/${eventId}`;
            setErrDelay(true);
            setLoading(true);
            const response = await serviceConsumer('GET', url);
            setAccommodations(response.Accomdations);
            setLoading(false);
            setErr('');
        } catch (error) {
            setErr(error.message);
            setLoading(false);
        }
    };

    useEffect(() => {
        getAllAccommodations();
    }, []);

    //to close any modal
    const modalCloseHandler = () => {
        dispatch({
            type: SET_MODAL,
            payload: {
                modal: '',
                eventId: '',
            },
        });
    };

    //to open add accommodation modal
    const openAddAccommodationModal = () => {
        dispatch({
            type: SET_MODAL,
            payload: {
                modal: 'AddAccommodation',
                eventId: eventId,
            },
        });
    };

    //to delete an accommodation
    const deleteHandler = async (accommodation) => {
        try {
            const url =
                ManageType.trim() == 'feis'
                    ? `${process.env.REACT_APP_BASE_URL}/feis/${eventId}/del/accomdations/${accommodation._id}`
                    : `${process.env.REACT_APP_BASE_URL}/grade-exam/${eventId}/del/accomdations/${accommodation._id}`;
            setLoading(true);
            await serviceConsumer('DELETE', url);
            getAllAccommodations();
            setLoading(false);
            setErr('');
        } catch (error) {
            setErr(error.message);
            setLoading(false);
        }
    };

    return (
        <div>
            {loading && <Loader />}
            <Modal className="addDancerScroll" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }} open={modalState}>
                <Box className="mui-box">
                    <div className="modal-box">
                        <div className="collaborator-modal">
                            <div className="close-div mt-4 mb-1">
                                <img src={closeIcon} alt="close-icon" className="close-icon" onClick={modalCloseHandler} />
                            </div>
                            <p className="color-subtitle text-center mb-4"> Accommodation</p>
                            <div
                                className="d-flex flex-column align-items-center justify-content-center"
                                style={{ background: 'white,borderRadius: 14px;' }}
                            >
                                {accommodations?.map((item) => {
                                    return (
                                        <div className="acc-card mb-3" key={item?._id}>
                                            <div className="accom-image">
                                                <img className="accom-image" src={item?.photo ? item?.photo : photoPlaceholder} />
                                            </div>
                                            <div className="acc-collab-right">
                                                <div className="d-flex justify-content-between" style={{ width: '100%' }}>
                                                    <div className="acc-collab-name">{item.name}</div>
                                                    <div onClick={() => deleteHandler(item)} className="acc-collab-del">
                                                        <DeleteOutlineIcon />
                                                    </div>
                                                </div>
                                                <div className="acc-collab-location"> {item?.country}</div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                            {err && (<div style={{ color: 'red', textAlign: 'center', fontSize: '12px', }}>{err}</div>)}
                            {!loading && accommodations.length === 0 && errDelay && (
                                <div style={{ color: 'red', textAlign: 'center', }}>
                                    There are no accommodations in this event
                                </div>
                            )}
                            <div className="d-flex justify-content-center my-3 ">
                                <AddButton className="plusIconbtn" onClick={openAddAccommodationModal}>
                                    Add Accommodation
                                </AddButton>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    );
};

export default AccomodationModal;
