import React, { useState } from 'react'
import edit from "../../Assets/edit-comp-action.svg"
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ConfirmDeleteModal from '../../Components/Modals/OrganizerModel/ManageFeis/Tabulate/ConfirmDeleteModal';
import SaveIcon from '@mui/icons-material/Save';
import { serviceConsumer } from '../../network/ServiceConsumer';
import Loader from '../../Components/Loader/Loader';
import { ShowToastMessage } from '../../helper/ShowToastMessage';
import SchoolFilters from './SchoolFilters';


const SchoolTable = ({ list, setList, page, getSchools, filter, setFilter }) => {
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [schoolId, setSchoolId] = useState("")
    const [deleteId, setDeleteId] = useState("")

    const serializeHelper = (page - 1) * 10

    const editClickHandler = (id) => {
        setDeleteId(id)
        setSchoolId(id)
    }

    const deleteClickHandler = (schoolId) => {
        setDeleteId(schoolId)
        setOpenDeleteModal(true)
    }

    const confirmDeleteHandler = async () => {
        try {
            setLoading(true)
            const url = `${process.env.REACT_APP_BASE_URL}/school/${deleteId}`;
            await serviceConsumer("DELETE", url)
            ShowToastMessage("success", "School deleted successfully")
            setOpenDeleteModal(false)
            getSchools()
            setLoading(false)
        } catch (err) {
            setLoading(false)
            ShowToastMessage("error", "Something went wrong")
        }
    }

    const cancelDeleteHandler = () => {
        setOpenDeleteModal(false)
    }
    const handleNameChange = (event, schoolId) => {
        const updatedList = list.map(school => {
            if (school._id === schoolId) {
                return { ...school, schoolName: event.target.value };
            }
            return school;
        });
        setList(updatedList);

    };

    const saveHandler = async (schoolId) => {
        try {
            setLoading(true)
            const url = `${process.env.REACT_APP_BASE_URL}/school/${schoolId}`;
            const school = list.find(item => item._id === schoolId)
            const payload = { schoolName: school.schoolName }
            await serviceConsumer("PUT", url, payload)
            setSchoolId("")
            ShowToastMessage("success", "School updated successfully")
            getSchools()
            setLoading(false)
        } catch (err) {
            setLoading(false)
            ShowToastMessage("error", "Something went wrong")
        }
    }
    return (
        <div style={{ width: "100%", overflowX: "scroll", marginTop: "30px", marginBottom: "50px" }}>
            {loading && <Loader />}
            <table className="table my_table competitorTable ">
                <tbody>
                    <tr className="header-row">
                        <td className="mx-5 w-3 p-25 text-center">S.No</td>
                        <td className="mx-5 w-8 p-25 ">School&nbsp;<SchoolFilters filter={filter} setFilter={setFilter} /></td>
                        <td className="mx-5 w-10 text-center p-25-5">Actions</td>
                    </tr>
                    {list?.map((school, index) => {
                        return <tr key={school?._id} style={{ fontSize: "12px", height: "61px" }}>
                            <td className="mx-5 w-3 p-25 text-center">{serializeHelper + (index + 1)}</td>

                            <td className="mx-5 w-8 p-25">
                                <input
                                    onChange={(event) => handleNameChange(event, school?._id)}
                                    value={school?.schoolName}
                                    className={schoolId === school?._id ? "dashboard-input" : "dashboard-input-disabled"} disabled={schoolId !== school._id} /></td>
                            <td className="w-10 text-center ">
                                <div className="d-flex justify-content-center gap-3">
                                    {schoolId === school?._id
                                        ? <SaveIcon onClick={() => saveHandler(school?._id)} style={{ cursor: "pointer", color: "#68ad3f" }} src={edit} alt="save" />
                                        : <img onClick={() => editClickHandler(school?._id)} style={{ cursor: "pointer" }} src={edit} alt="edit" />
                                    }
                                    <DeleteOutlineOutlinedIcon
                                        style={{ color: "red", marginLeft: "10px", cursor: "pointer" }}
                                        onClick={() => deleteClickHandler(school?._id)}
                                    />
                                </div>
                            </td>
                        </tr>
                    })}
                </tbody>
            </table>
            {openDeleteModal && <ConfirmDeleteModal modalState={openDeleteModal} confirm={confirmDeleteHandler} cancel={cancelDeleteHandler} dancerPage={true} />}
        </div>
    )
}

export default SchoolTable
