import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { createTheme, ThemeProvider } from '@mui/material';
import { SIGNIN } from './redux/actionTypes/authActionType';
import { PageNotFound } from './Components/Errors/PageNotFound';
import { routesArray } from './routes';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


// organizer Routes
import GradeOrg from './Organizer/CreateGradeEvent/GradeOrg';
import FiesOrg from './Organizer/CreateFeisEvent/FiesOrg';
import LoginOrg from './Organizer/Auth/LoginOrg';
import ForgotPasswordOrg from './Organizer/Auth/ForgotPasswordOrg';
import ConfirmPasswordOrg from './Organizer/Auth/ConfirmPasswordOrg';
import DashboardOrg from './Organizer/Dashboard/DashboardOrg';
import ManageFeisGrade from './Organizer/ManageFeisGrade/ManageFeisGrade';
import EventDetails from './Organizer/ManageFeisGrade/RedirectFeis/Event/EventDetails';
import StripeAccount from './Organizer/Payment/StripeAccount';
import FeisCompetitors from './Organizer/ManageFeisGrade/RedirectFeis/Entries/FeisCompetitors';
import EditCompetitors from './Organizer/ManageFeisGrade/RedirectFeis/Entries/EditCompetitors';
import EditRegister from './Organizer/ManageFeisGrade/RedirectFeis/Entries/EditRegister';
import MergeSplit from './Organizer/ManageFeisGrade/RedirectFeis/Entries/MergeSplit';
import MergeSplitFn from './Organizer/ManageFeisGrade/RedirectFeis/Entries/MergeSplitFn';
import Email from './Organizer/ManageFeisGrade/RedirectFeis/Entries/Email';
import FinalFiezDetails from './Organizer/GoLive/FinalFiezDetails';
import CheckIn from './Organizer/ManageFeisGrade/RedirectFeis/Tabulation/CheckIn';
import ResultStatus from './Organizer/ManageFeisGrade/RedirectFeis/Tabulation/ResultStatus';
import Tabulate from './Organizer/ManageFeisGrade/RedirectFeis/Tabulation/Tabulate';
import CompleteResults from './Organizer/ManageFeisGrade/RedirectFeis/Tabulation/CompleteResults';
import ScoreCheckInDownload from './Organizer/ManageFeisGrade/RedirectFeis/Print/ScoreCheckInDownload';
import Transactions from './Organizer/ManageFeisGrade/RedirectFeis/Financial/Transactions';
import CandidateLists from './Organizer/ManageFeisGrade/RedirectGrade/Entries/CandidateLists';
import PublishedResults from './Organizer/ManageFeisGrade/RedirectFeis/Tabulation/PublishedResults';
import ManageUsers from './Organizer/Dashboard/ManageUsers';
import AwardsTableComponent from './Organizer/AwardsTable/AwardsTableComponent';
import ManageSchools from './Organizer/Dashboard/ManageSchools';
import EditCandidates from './Organizer/ManageFeisGrade/RedirectGrade/Entries/EditCandidates';
import GradeEditRegister from './Organizer/ManageFeisGrade/RedirectGrade/Entries/GradeEditRegister';
import Dashboard from './Organizer/Dashboard/Dashboard';
import CreateEventFromPrev from './Organizer/CreateEventFromPrev/CreateEventFromPrev';
import ManageSchedule from './Organizer/ManageFeisGrade/RedirectFeis/Schedule/ManageSchedule';
import ManageCollaborators from './Organizer/Dashboard/ManageCollaborators';


const theme = createTheme({
  palette: {
    primary: {
      main: '#68AD3F',
    },
    secondary: {
      main: '#FBB040',
    },
  },

  typography: {
    fontFamily: '"Rubik", sans-serif ',
    h1: {
      fontSize: '52px',
      fontWeight: 700,
    },
    h2: {
      fontSize: '40px',
      fontWeight: 500,
    },
    button: {
      textTransform: 'none',
    },
  },
});
const NotFoundRedirect = () => {
  if (routesArray.includes(window.location.pathname)) {
    const fullPath = window.location.pathname + window.location.search;
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    const type = params.get('type');
    if (type === "redirect") {
      localStorage.setItem("orgPath", fullPath)
      localStorage.setItem("redirectFromOrg", "redirect")
    }
    return <Navigate to="/" state={{ from: '404PAGE' }} />;
  } else {
    return <PageNotFound />;
  }
};

export const AppWrapper = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isToastVisible, setIsToastVisible] = useState(true);
  const accessToken = localStorage.getItem('accessToken');
  //Authenticating user while the session is live or user is logged in
  const authenticateUser = () => {
    if (accessToken) {
      dispatch({
        type: SIGNIN,
        payload: {
          accessToken,
        },
      });
    }
  };
  useEffect(() => {
    authenticateUser();
  }, []);

  // Add a request interceptor
  axios.interceptors.request.use(
    (config) => {
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  axios.interceptors.response.use(handleResponse, handleError);

  function handleResponse(response) {
    return response;
  }

  function handleError(error) {
    if (error.response.status === 401) {
      localStorage.clear();
      navigate('/', {
        state: { from: `${error.response.data.message.name}` },
      });
      return Promise.reject(error);
    }

    if (error.response.status === 500) {
      if (isToastVisible) {
        toast.dismiss();
        toast.error('Some Thing went wrong', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 100,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        setIsToastVisible(false);
      }
    }


    if (error.response.status === 403) {
      localStorage.clear();
      navigate('/', {
        state: { from: `${error.response.data.message}` },
      });
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        {accessToken ? (
          <Routes>
            <Route exact path="/" element={<Dashboard />} />
            <Route path="/feis/manage" element={<ManageFeisGrade />} />
            <Route path="/grade/manage" element={<ManageFeisGrade />} />
            <Route path="/event-details" exact element={<EventDetails />} />
            <Route path="/users" exact element={<ManageUsers />} />
            <Route path="/schools" exact element={<ManageSchools />} />
            <Route path="/collaborators" exact element={<ManageCollaborators />} />
            <Route path="/feis" exact element={<DashboardOrg />} />
            <Route path="/grade" exact element={<DashboardOrg />} />
            <Route path="/feis/create" exact element={<FiesOrg />} />
            <Route path="/grade/create" exact element={<GradeOrg />} />
            <Route path="/previous-events" element={<CreateEventFromPrev />} />


            <Route path="/grade/create/finalGradeDetails" element={<FinalFiezDetails type="grade" />} />
            <Route path="/feis/create/finalFeisDetails" element={<FinalFiezDetails type="feis" />} />
            <Route path="/competitorss/:name" element={<FeisCompetitors />} />
            <Route path="/candidate/:name" element={<CandidateLists />} />
            <Route path="/edit-candidates/:name" element={<EditCandidates />} />
            <Route path="/grade/edit-register/:name" exact element={<GradeEditRegister />} />
            <Route path="/edit-competitors/:name" element={<EditCompetitors />} />
            <Route path="/stripe-account" exact element={<StripeAccount />} />
            <Route path="/edit-register/:name" exact element={<EditRegister />} />
            <Route path="/merge-split/:name" exact element={<MergeSplit />} />
            <Route path="/merge-split-comp/:name" exact element={<MergeSplitFn />} />
            <Route path="/email" exact element={<Email />} />
            <Route path="/check-in/:name" exact element={<CheckIn />} />
            <Route path="/result-status/:name" exact element={<ResultStatus />} />
            <Route path="/tabulate/:name" exact element={<Tabulate />} />
            <Route path="/complete-results/:name" exact element={<CompleteResults />} />
            <Route path="/score-checkin-download/:name" exact element={<ScoreCheckInDownload />} />
            <Route path="/transaction-history/:name" exact element={<Transactions />} />
            <Route path="/published-results/:name" exact element={<PublishedResults />} />
            <Route path="/awards" exact element={<AwardsTableComponent />} />
            <Route path="/manage-schedule" exact element={<ManageSchedule />} />
            <Route path="*" exact element={<PageNotFound />} />

          </Routes>
        ) : (
          <Routes>
            <Route path="/" element={<LoginOrg />} />
            <Route path="/admin/login" element={<LoginOrg />} />
            <Route path="/forgotpwd" element={<ForgotPasswordOrg />} />
            <Route path="/reset-pwd" element={<ConfirmPasswordOrg />} />
            <Route path="*" element={<NotFoundRedirect />} />
          </Routes>
        )}
        <ToastContainer />;
      </ThemeProvider>
    </div>
  );
};
