import React, { useRef, useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';

import closeIcon from '../../../../Assets/close-icon.svg';
import { serviceConsumer } from '../../../../network/ServiceConsumer';
import { handlePhotoUpload } from '../../../../helper/handleUploads';
import AddButton from '../../../AddButton/AddButton';
import Loader from '../../../Loader/Loader';
import CustomInput from '../CustomInput';

const NewCollaboratorModal = ({ setOpenCollabModal, openNewCollabModal, setOpenNewCollabModal, getCollab, from }) => {
    const [imgError, setImgErr] = useState('');
    const [logo, setLogo] = useState('');
    const [upl, setUpl] = useState('');
    const [loading, setLoading] = useState(false);
    const imgInputRef = useRef(null);
    let EventID = localStorage.getItem('ID');

    const handleLOgo = () => {
        imgInputRef.current.click();
    };

    //Initial values for formik
    const newCollabInitialValues = {
        name: '',
        location: '',
        type: openNewCollabModal,//mention the modalname
        photo: '',
    };

    const newCollabValidationSchema = Yup.object().shape({
        name: Yup.string()
            .trim()
            .label('First Name:')
            .required('This field is required.')
            .nullable()
            .max(50),
        location: Yup.string()
            .trim()
            .label('Last Name:')
            .required('This field is required.')
            .nullable()
            .max(50),
    });

    const handleFileSelect = async (event) => {
        handlePhotoUpload(event, setLoading, setLogo, setUpl, setImgErr);
    };

    const addCollab = async (values, formikHelpers) => {
        const payload = {
            name: values.name,
            location: values.location,
            type: values.type,
            photo: logo,
            eventId: EventID,
        };
        try {
            setLoading(true);
            const type = from === "grade" ? "grade-exam" : "people"
            const url = `${process.env.REACT_APP_BASE_URL}/${type}/${openNewCollabModal}`;///people/musician,/grade-exam/examiner
            await serviceConsumer('POST', url, payload);
            setOpenNewCollabModal("");
            setOpenCollabModal("")
            getCollab();
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const closeModalHandler = () => {
        console.log(openNewCollabModal)
        //close this modal and open previous modal->select collaborator
        if (openNewCollabModal !== "sponsor") {
            setOpenCollabModal(openNewCollabModal);
            setOpenNewCollabModal("")
        } else {
            setOpenNewCollabModal("")
            setOpenCollabModal("")
        }

    }

    const capitalizeFirstLetter = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    return (
        <>
            {loading && <Loader />}
            <Modal className="addDancerScroll" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }} open={true}>
                <Box>
                    <div style={{ maxHeight: '100vh', padding: '5px 100vw', overflow: 'scroll', }}>
                        <div className="main-div-add-adj">
                            <div className="close-div mt-4 mb-1">
                                <img src={closeIcon} alt="close-icon" className="close-icon" onClick={closeModalHandler} />
                            </div>
                            <p className="color-subtitle text-center mb-4" style={{ marginTop: '40px' }}>{`Add ${capitalizeFirstLetter(openNewCollabModal)}`}</p>
                            <div className="d-flex align-items-center justify-content-center" style={{ background: 'white,borderRadius: 14px;' }}>
                                <Formik
                                    initialValues={newCollabInitialValues}
                                    validationSchema={newCollabValidationSchema}
                                    onSubmit={addCollab}
                                >
                                    {(formik) => (
                                        <Form className="form form-label-right" autoComplete="off">
                                            <CustomInput label="Full name" name="name" type="text" placeholder="Name" formik={formik} />

                                            <CustomInput label="Location" name="location" type="text" placeholder="Location" formik={formik} />

                                            <input type="file" ref={imgInputRef} style={{ display: 'none' }} onChange={handleFileSelect} />

                                            <div className="d-grid">
                                                <label className="label-head-org" style={{ marginLeft: '1px' }} >Photo:</label>
                                                <div>
                                                    <AddButton onClick={handleLOgo} className="plusIconbtn mt-0">Add Photo</AddButton>
                                                </div>
                                                {imgError && (<span className="error mt-2" style={{ width: '248px' }}>{imgError}</span>)}
                                                {upl && <span className="auth-suc">{upl}</span>}
                                            </div>
                                            <div className="mb-5 d-flex justify-content-center">
                                                <button className={formik.isValid && formik.dirty ? 'updateChange' : 'updateChangeDisabled'}
                                                    style={{ fontWeight: '500', height: '42px', padding: '9px 11px', }}
                                                    type="submit"
                                                    disabled={!formik.isValid || !formik.dirty || formik.isSubmitting}
                                                >
                                                    {`Add ${openNewCollabModal}`}
                                                </button>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>
        </>
    );
};


export default NewCollaboratorModal