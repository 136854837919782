import React, { useEffect, useState } from 'react';
import Help from '../../Components/Help/Help';
import HeaderOrg from '../Headers/HeaderOrg';
import './DashboardOrg.css';
import Tab from '@mui/material/Tab';
import { TabPanel } from '@mui/lab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Events from './Events';
import Closed from './Closed';
import { ResetLocalStoragePagination, setLocalOnOrgdashboardLoading } from '../../helper/setLocalStorage';
import Back from '../../Components/Back/Back';
import { useLocation, useNavigate } from 'react-router-dom';
import CreateEventModal from '../../Components/Modals/OrganizerModel/CreateEventModal';

const DashboardOrg = () => {
  const [value, setValue] = useState("1");
  const [openModal, setOpenModal] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const isFeis = location.pathname === "/feis"
  const eventType = location.pathname === "/feis" ? "Feiseanna" : "Grade Exam"
  localStorage.removeItem("closed")

  //tab change handler
  const handleChange = (event, value) => {
    setValue(value);
    ResetLocalStoragePagination();
  };

  useEffect(() => {
    setLocalOnOrgdashboardLoading();
  }, []);

  const navigateToDashboard = () => {
    navigate("/")
  }

  const openCreateModal = () => {
    setOpenModal(true)
  }

  const modalCloseHandler = () => {
    setOpenModal(false)
  }
  return (
    <>
      <HeaderOrg />
      <div className="db-container">
        {/* <p className="hi-name mb-0">Hi, Admin!</p> */}
        <div className="db-tab-container">
          <div className="db-tabs-div mt-3">
            <Back onBackClick={navigateToDashboard} />
          </div>
          <TabContext value={value}>
            <TabList className="db-tabs-div d-flex " onChange={handleChange} aria-label="lab API tabs example" >
              {/* <Tab className="db-tab" label="Dashboard" value="1" /> */}
              <Tab className="db-tab" label="Events" value="1" />
              <Tab className="db-tab" label="Closed events" value="2" />
              <div className="flex-grow-1 d-flex justify-content-end align-items-center align-items-sm-start db-dropdown">
                <div classname="flex-grow-1 ">
                  <button className="filled" onClick={openCreateModal}>Create event</button>
                </div>
              </div>
            </TabList>
            <div className="hr-org-title" style={{ marginTop: '-2px' }} />
            {/* <TabPanel value="1">
              <Dashboard />
            </TabPanel> */}
            <TabPanel value="1">
              <Events feis={isFeis} />
            </TabPanel>
            <TabPanel value="2">
              <Closed feis={isFeis} />
            </TabPanel>
          </TabContext>
          {openModal && <CreateEventModal modalState={true} clickHandler={modalCloseHandler} eventType={eventType}/>}
        </div>
      </div>
      <Help />
    </>
  );
};

export default DashboardOrg;
