import React, { useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';

import closeIcon from "../../../../Assets/close-icon.svg"
import FormikControl from '../../../formik/FormikControl';
import AddButton from '../../../AddButton/AddButton';
import { serviceConsumer } from '../../../../network/ServiceConsumer';
import Loader from '../../../Loader/Loader';

//this modal shows all old collaborators, where we can select one
//works for adjudicators and musicians in feis,examiners in grade. Sponsors cannot be selected from old collaborators
const SelectCollaborators = ({ setOpenCollabModal, openCollabModal, getCollab, setOpenNewCollabModal }) => {

    const [collaborators, setCollaborators] = useState([]);
    const [loading, setLoading] = useState(false);
    let EventID = localStorage.getItem('ID');

    useEffect(() => {
        getCollaboratorData();
    }, []);

    const getCollaboratorData = async () => {
        //get data to show in options of select
        try {
            setLoading(true);
            const url = `${process.env.REACT_APP_BASE_URL}/people/${openCollabModal}`;
            const response = await serviceConsumer('GET', url);
            const collabData = response.people.map((collab) => {
                return {
                    key: collab.name,
                    value: collab._id,
                };
            })
            setCollaborators(collabData);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    //Initial values for formik
    const collaboratorInitialValues = {
        collaborator: '',
    };

    //Validation Schema for Formik
    const collaboratorValidationSchema = Yup.object().shape({
        collaborator: Yup.string()
            .trim()
            .label('First Name:')
            .required('This field is required.')
            .nullable()
            .max(50),
    });

    // submit Handler
    const addCollabHandler = async (values) => {
        const type = openCollabModal === "examiner" ? "grade-exam" : "people";
        const url = `${process.env.REACT_APP_BASE_URL}/${type}/${openCollabModal}`;
        const payload = {
            eventId: EventID,
            peopleId: values.collaborator,
        };
        try {
            setLoading(true);
            const response = await serviceConsumer('POST', url, payload);
            if (response.success === true) {
                setOpenCollabModal("");
                getCollab();
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const closeModalHandler = () => {
        setOpenCollabModal("");
    }

    const openNewCollabModal = () => {
        let modalName = openCollabModal
        setOpenCollabModal("");
        setOpenNewCollabModal(modalName)
    }

    const capitalizeFirstLetter = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    return (
        <>
            {loading && <Loader />}
            <div style={{ padding: '100px', overflow: 'scroll' }}>
                <Modal className="addDancerScroll" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }} open={true}>
                    <Box>
                        <div style={{ maxHeight: '100vh', padding: '5px 100vw', overflow: 'scroll', }}>
                            <div className="main-div-adj">
                                <div className="close-div mt-4 mb-1">
                                    <img src={closeIcon} alt="close-icon" className="close-icon" onClick={closeModalHandler} />
                                </div>
                                <p className="color-subtitle text-center mb-4"> {`Add ${capitalizeFirstLetter(openCollabModal)}`}</p>
                                <div className="d-flex align-items-center justify-content-center" style={{ background: 'white,borderRadius: 14px;' }}>
                                    <Formik
                                        initialValues={collaboratorInitialValues}
                                        validationSchema={collaboratorValidationSchema}
                                        onSubmit={addCollabHandler}
                                    >
                                        {(formik) => (
                                            <Form
                                                className="form form-label-right"
                                                autoComplete="off"
                                            >
                                                <div className={(formik.errors?.collaborator && formik.touched?.collaborator) ? '' : 'input-control'}>
                                                    <label className={(formik.touched?.collaborator && formik.errors?.collaborator) ? 'select-label-error' : 'select-label'} htmlFor='input-box-org-select'>
                                                        {`${capitalizeFirstLetter(openCollabModal)}`}:
                                                    </label>
                                                    <FormikControl
                                                        className={(formik.touched?.collaborator && formik.errors?.collaborator) ? "input-box-org-select-error" : "input-box-org-select"}
                                                        control="select"
                                                        name="collaborator"
                                                        options={collaborators}
                                                        showAs="Select"
                                                        style={{ color: 'black' }}
                                                        id="AddAdjucator"
                                                        setFieldValue={formik.setFieldValue}
                                                    />
                                                </div>
                                                <span className="DidnontFind"> Didn't find the {`${capitalizeFirstLetter(openCollabModal)}`}?</span>


                                                <div className=" d-flex justify-content-center mt-4">
                                                    <AddButton className="plusIconbtn" onClick={openNewCollabModal} from="collab">
                                                        Add new {openCollabModal}
                                                    </AddButton>
                                                </div>
                                                <div className="mb-5 d-flex justify-content-center ">
                                                    <button className={formik.isValid && formik.dirty ? 'filled' : 'disabled'}
                                                        type="submit"
                                                        disabled={!formik.isValid || !formik.dirty || formik.isSubmitting}
                                                    >
                                                        Continue
                                                    </button>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </Box>
                </Modal>
            </div>
        </>
    )
}

export default SelectCollaborators