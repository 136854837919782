import React from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import logout from "../../../Assets/logout.svg";
import closeButton from "../../../Assets/closeButton.svg";
import "./LogoutModal.css";
import { LOGOUT } from "../../../redux/actionTypes/authActionType";

const LogoutModal = ({ show, setShow }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClose = async () => {
    dispatch({
      type: LOGOUT,
    });
    setShow(false);
    localStorage.clear();
    navigate("/");
  };
  return (
    <>

      <Modal show={show} onHide={() => setShow(false)} aria-labelledby="contained-modal-title-vcenter" centered>

        <div className="d-flex justify-content-end p-4">
          <img onClick={() => setShow(false)} src={closeButton} alt="" className="cursor-pointer" />
        </div>

        <Modal.Body className="">
          <img src={logout} alt="logout" />
          <h5 className="my-3">Sign out</h5>
          <p className="m-0 green">Are you sure you want to logout?</p>
        </Modal.Body>

        <Modal.Footer>
          <button className="filled mx-3" onClick={() => setShow(false)}>Cancel</button>
          <button className="outlined" onClick={handleClose}>Sign out</button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default LogoutModal;
