import React, { useEffect, useState } from 'react';
import { Form, Formik, useFormikContext } from 'formik';
import { serviceConsumer } from '../../network/ServiceConsumer';
import { useDispatch } from 'react-redux';
import Loader from '../../Components/Loader/Loader';
import { Container } from 'react-bootstrap';
import { accessTillAbout, accessTillFees } from '../../helper/CreateDispatches';
import { feesValidationSchema } from '../../helper/Validations';
import FamilyFeeAndMax from './Components/FeesComp/FamilyFeeAndMax';
import EarlyAndLateFee from './Components/FeesComp/EarlyAndLateFee';
import { formatDate } from '../../helper/formatDate';
import moment from 'moment';


const FeesFies = (props) => {
  let EventID = localStorage.getItem('ID');
  const [EventDetails, setEventDetails] = useState('');
  const [closingDate, setClosingDate] = useState('');
  const [familyFee, setFamilyFee] = useState();
  const [loading, setLoading] = useState(false);
  const page = localStorage.getItem('page');
  const TAB = localStorage.getItem('TAB');
  const formik = useFormikContext();
  const dispatch = useDispatch();
  const previousEvent = localStorage.getItem('previousEvent');
  const eventType = props.type;

  useEffect(() => {
    accessTillFees(dispatch)
    getEventDetails();
  }, []);


  const getEventDetails = async () => {
    try {
      setLoading(true);

      if (EventID) {
        const url = eventType === "feis"
          ? `${process.env.REACT_APP_BASE_URL}/api/admin/feis/${EventID}`
          : `${process.env.REACT_APP_BASE_URL}/api/admin/grade/${EventID}`;
        const response = await serviceConsumer('GET', url);
        const res = eventType === "feis" ? response?.Feis : response?.grade_exams
        setFamilyFee(res?.fees[0]);
        if (response.success) {
          setClosingDate(res?.closing_date)
          setEventDetails(res.fees[0]);
          formik.setFieldValue('fees.familyfee', EventDetails?.familyfee);
          page > TAB && localStorage.setItem('page', res.page);
          setLoading(false);
        }
        setLoading(false);
      }
      if (!EventDetails) {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const ifDateIsBefore = EventDetails?.earlyFee?.ifDateIsBefore;
  const afterDate = EventDetails?.lateFee?.afterDate

  const EventDetailsValues = {
    familyfee: EventDetails?.familyfee,
    familymax: EventDetails?.familymax,
    familyless: EventDetails?.earlyFee?.familyLess,
    ifDateIsBefore: ifDateIsBefore ? formatDate(ifDateIsBefore) : '',
    additionfee: EventDetails?.lateFee?.additionalFee,
    afterdate: afterDate ? formatDate(afterDate) : '',
    lateadditionfee: EventDetails?.lateFeeAfterClosed?.additionalFee,
    lateafterdate: closingDate ? formatDate(closingDate) : '',
  };

  const checkValidAfterDate = (formik) => {
    if (afterDate && closingDate) {
      formik.setFieldTouched('afterdate', true);
      setTimeout(() => {
        formik.validateForm();
      }, 0);
    }
  }




  // submitHandler
  const FamilyFeesHandler = async (val) => {

    let fees = {
      fees: [
        {
          familyfee: val.familyfee,
          familymax: val.familymax,
          earlyFee: {
            familyLess: val.familyless,
            ifDateIsBefore:  moment(val.ifDateIsBefore).format("yyyy-MM-DD"),
          },
          lateFee: {
            additionalFee: val.additionfee,
            afterDate: moment(val.afterdate).format("yyyy-MM-DD"),
          },
          lateFeeAfterClosed: {
            additionalFee: val.lateadditionfee,
            afterDate:  moment(val.lateafterdate).format("yyyy-MM-DD"),
          },
        },
      ],
      page: localStorage.getItem('page') > 4 ? localStorage.getItem('page') : 4,
    };

    try {
      setLoading(true);
      const url = eventType === "feis"
        ? `${process.env.REACT_APP_BASE_URL}/feis/${EventID}`
        : `${process.env.REACT_APP_BASE_URL}/grade-exam/${EventID}`;
      const response = await serviceConsumer('PUT', url, fees);

      if (response.success === true) {
        props.setValue('5');
        localStorage.setItem('TAB', 5);
        if (Number(localStorage.getItem('page')) < 4) {
          localStorage.setItem('page', 4);
        }
        accessTillAbout(dispatch)
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const getButtonClass = (formik) => {
    if (previousEvent) return 'filled'
    else if (formik.isValid && formik.dirty) return 'filled'
    else return 'disabled'
  }


  return (
    <Container className="px-0">
      {loading && <Loader />}
      {!loading && (
        <Formik
          initialValues={EventDetailsValues}
          validationSchema={feesValidationSchema}
          onSubmit={FamilyFeesHandler}
          validateOnChange
          validateOnBlur
        >
          {(formik) => {
            return (
              <Form className="form form-label-right" autoComplete="off" onChange={() => { checkValidAfterDate(formik) }}>
                <div className="">
                  <div className="detailsHeaders">
                    <h4 className="eventPage">Fees</h4>
                    <p className="eventDescription">
                      Pay with your Stripe account
                    </p>
                  </div>
                  <div>
                    <h3 className="EventDateOrg">Family Fee and Family Max</h3>
                  </div>
                  <div className="d-flex flex-column ">
                    <FamilyFeeAndMax formik={formik} familyFee={familyFee} />
                    <EarlyAndLateFee formik={formik} />
                  </div>
                  <div className=" mt-3 mb-5" >
                    <button
                      disabled={(!formik.isValid || !formik.dirty || formik.isSubmitting) && !previousEvent}
                      className={getButtonClass(formik)}
                      type="submit"
                    >
                      Next
                    </button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      )}
    </Container>
  );
};

export default FeesFies;


