// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1 {
  font-size: 52px !important;
}
h2 {
  font-size: 40px !important;
}

h3 {
  font-size: 30px !important;
}
.subtitle-font {
  font-size: 21px;
  line-height: 32px;
  color: rgba(0, 0, 0, 0.702469);
}
.color-primary {
  color: #68ad3f;
}
.color-subtitle {
  color: #323232;
  font-size: 24px;
  font-weight: 400;
}
.font-opacity-2 {
  opacity: 0.2;
}
.no-pointer-events{
  pointer-events: none !important;
}

/* --------------------------------Reusable classes start--------------------------------- */
.border-btm {
  border-bottom: 1px solid #8cae781f;
  z-index: -1;
  position: absolute;
  width: 100%;
  left: 0px;
}
/* --------------------------------Reusable classes end--------------------------------- */
@media (min-width: 0px) and (max-width: 560px) {
  h1 {
    font-size: 24px !important;
  }
  h2 {
    font-size: 20px !important;
    font-weight: 500;
  }
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AACA;EACE,SAAS;EACT,mCAAmC;EACnC,kCAAkC;AACpC;AACA;EACE,0BAA0B;AAC5B;AACA;EACE,0BAA0B;AAC5B;;AAEA;EACE,0BAA0B;AAC5B;AACA;EACE,eAAe;EACf,iBAAiB;EACjB,8BAA8B;AAChC;AACA;EACE,cAAc;AAChB;AACA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;AAClB;AACA;EACE,YAAY;AACd;AACA;EACE,+BAA+B;AACjC;;AAEA,4FAA4F;AAC5F;EACE,kCAAkC;EAClC,WAAW;EACX,kBAAkB;EAClB,WAAW;EACX,SAAS;AACX;AACA,0FAA0F;AAC1F;EACE;IACE,0BAA0B;EAC5B;EACA;IACE,0BAA0B;IAC1B,gBAAgB;EAClB;AACF","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap\");\nbody {\n  margin: 0;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\nh1 {\n  font-size: 52px !important;\n}\nh2 {\n  font-size: 40px !important;\n}\n\nh3 {\n  font-size: 30px !important;\n}\n.subtitle-font {\n  font-size: 21px;\n  line-height: 32px;\n  color: rgba(0, 0, 0, 0.702469);\n}\n.color-primary {\n  color: #68ad3f;\n}\n.color-subtitle {\n  color: #323232;\n  font-size: 24px;\n  font-weight: 400;\n}\n.font-opacity-2 {\n  opacity: 0.2;\n}\n.no-pointer-events{\n  pointer-events: none !important;\n}\n\n/* --------------------------------Reusable classes start--------------------------------- */\n.border-btm {\n  border-bottom: 1px solid #8cae781f;\n  z-index: -1;\n  position: absolute;\n  width: 100%;\n  left: 0px;\n}\n/* --------------------------------Reusable classes end--------------------------------- */\n@media (min-width: 0px) and (max-width: 560px) {\n  h1 {\n    font-size: 24px !important;\n  }\n  h2 {\n    font-size: 20px !important;\n    font-weight: 500;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
